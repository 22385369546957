import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
} from "mdbreact";

class Q42021NewsletterBlog extends Component {
  state = {
    modal: false,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    return (
      <React.Fragment>
        <section className="Blog-Banner BlogDetails-Banner">
          <img src="" alt="" />
          <MDBContainer className="Blog-Title">
            <MDBRow>
              <MDBCol size="12">
                <div className="BannerTitle">
                  <h1>
                    Welcome to our Fourth Quarter NU Borders' Business
                    Intelligence for Trade Enforcement (BITE) Newsletter!
                  </h1>
                </div>

                <div className="Statusbar">
                  <div className="Timing">
                    <img src="" alt="" />
                    <h6>11/1/2021</h6>
                  </div>

                  <div className="Timing">
                    <img src="" alt="" />
                    <h6>Newsletter</h6>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>

          <div className="Banner">
            <img
              src={`${process.env.PUBLIC_URL}/images/pexels-marc-mueller-380769.png`}
              className="banner-bg"
              alt=""
            />
          </div>
        </section>

        <section className="Blogs-Description">
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12">
                <div className="Description">
                  <p>
                    BITE is a Software as a Service (SaaS) platform to help
                    compliance and enforcement teams quickly ingest and analyze
                    supply chain data to find patterns and areas of
                    non-compliance. It includes workflow automation and
                    reporting capabilities to make compliance tasks faster and
                    more efficient.
                  </p>
                  <div>
                    <h3>What we've been up to:</h3>
                    <div>
                      <ol>
                        <li>
                          We're building BITE v2.0 - a brand new platform that
                          will provide enhanced workflow, tracking, and
                          analytical and reporting tools for trade compliance
                          personnel and enforcement operators. Lookout for the
                          release in early 2022!
                        </li>
                        <li>
                          We've published a new version of the NU Borders'
                          website in preparation for launching BITE v2.0.
                        </li>
                        <li>
                          We've created clear marketing material, including 8
                          use cases specific to our customers' mission focus and
                          NU Borders' domains - we'll be posting these to our
                          website soon.
                        </li>
                        <li>
                          We've solidified a partnership with Zambesia
                          Conservation Alliance to bring data analytics to
                          international wildlife trafficking organizations.
                        </li>
                        <li>
                          The team procured and has normalized, ingested and
                          enriched 3 new country datasets for the BITE platform,
                          to add to our over 300-million records worth of trade
                          data.
                        </li>
                        <li>
                          We conducted a 3-day virtual BITE analyst training for
                          Indonesian government end-users in the customs and
                          enforcement sector.
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div>
                    <h3>What we've learned: </h3>
                    <ol>
                      <li>
                        Completely rebuilding a platform based on the needs of
                        your customer base is hard - but with the right people
                        it is achievable.{" "}
                      </li>
                      <li>
                        The BITE product has changed a lot since we first came
                        up with the concept of BITE - the product definition
                        needs to be clear, so our customer understands exactly
                        what we are offering.{" "}
                      </li>
                      <li>
                        We need to interpret the data and present it to our
                        customers in an easy to digest manner - we will continue
                        to alter and redesign our investigative one-pagers.{" "}
                      </li>
                      <li>
                        Targeted marketing materials and building our brand is
                        critical to expansion.
                      </li>
                    </ol>
                  </div>
                  <div>
                    <h3>What we're doing next: </h3>
                    <ol>
                      <li>
                        NU Borders will host a free quarterly BITE Webinar on
                        Supply Chain for Trade Compliance in December 2021. Our
                        team of trade compliance and IT experts will highlight
                        how current and former customers have integrated these
                        powerful BITE tools to support their compliance needs.
                      </li>
                      <li>
                        We are kicking off 4 new projects and grants with the
                        Department of State, focused on the use of BITE to
                        combat illicit trade-based activity in 5 key
                        geographical regions worldwide.{" "}
                      </li>
                      <li>
                        NU Borders will deliver a 3-day Supply Chain Compliance
                        workshop on the BITE platform for Ukrainian government
                        representatives at the end of October.{" "}
                      </li>
                      <li>
                        We will begin testing of BITE v2.0 - no bugs allowed!{" "}
                      </li>
                      <li>
                        We will continue to listen to our customers, mentors and
                        business partners as we grow the brand and product.{" "}
                      </li>
                    </ol>
                  </div>
                </div>
              </MDBCol>
              <MDBCol size="12" className="mt-3">
                <div>
                  <Link to="/blog">
                    <MDBBtn className="ThemeBtn">Back to blogs</MDBBtn>
                  </Link>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <MDBModal
          className="MemberProfile BlogShare"
          backdropClassName="ProfilePop"
          disableBackdrop
          isOpen={this.state.modal}
          toggle={this.toggle}
          centered
        >
          <MDBBtn onClick={this.toggle} className="CancelBtn">
            <img src={`${process.env.PUBLIC_URL}/images/cancel.svg`} alt="" />
          </MDBBtn>
          <MDBModalBody>
            <div className="BlogShare-ModelBody">
              <h4 className="Title">Share</h4>
              <div className="UR-Info">
                <div className="URL">
                  <p>bitly.xyz.13haerrvba…</p>
                </div>
                <MDBBtn className="CopyBtn">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/copy-btn.svg`}
                    alt=""
                  />
                </MDBBtn>
              </div>

              <div className="Share-Social">
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social1.svg`}
                    alt=""
                  />
                </MDBBtn>
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social2.svg`}
                    alt=""
                  />
                </MDBBtn>
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social3.svg`}
                    alt=""
                  />
                </MDBBtn>
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social4.svg`}
                    alt=""
                  />
                </MDBBtn>
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social5.svg`}
                    alt=""
                  />
                </MDBBtn>
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social6.svg`}
                    alt=""
                  />
                </MDBBtn>
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social7.svg`}
                    alt=""
                  />
                </MDBBtn>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-5X4Q69C"
            height="0"
            width="0"
            title="seo"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
      </React.Fragment>
    );
  }
}

export default Q42021NewsletterBlog;
