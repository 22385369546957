import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import { BtnArrowSmall } from "./Icons";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <React.Fragment>
      <section className="Mainbaner position-relative">
        <video className="video" autoPlay={true} loop="loop" muted>
          <source
            src={`${process.env.PUBLIC_URL}/images/large-container-ship-sea.mp4`}
            type="video/mp4"
          />
        </video>
        <MDBContainer className="overlays">
          <MDBRow className="">
            <MDBCol size="12">
              <div className="Homeinnerbox">
                <h1>
                  Custom Solutions <br /> Unbounded Innovative Ideas <br />{" "}
                  Automated Compliance
                </h1>
                <h3 className="my-4">
                  NU Borders Leverages Advanced Analytics and <br /> Technology
                  to Solve Big Problems
                </h3>

                <div className="bothbtns">
                  <Link to="/contact">
                    {" "}
                    <MDBBtn className="ThemeBtn">REQUEST A DEMO</MDBBtn>{" "}
                  </Link>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <section className="OurSolutions py-3 my-md-5 my-3">
        <div className="bg_img">
          <img src={`${process.env.PUBLIC_URL}/images/solutonBg.png`} alt="" />
        </div>

        <MDBContainer>
          <MDBRow className="">
            <MDBCol size="12" md="12" sm="12">
              <div className="Titles">
                <h2>Our Solutions</h2>
              </div>
            </MDBCol>
          </MDBRow>

          <MDBRow className="SoluTops">
            <MDBCol size="12" md="4" sm="12" className="mt-10 mt-sm-0">
              <div className="SolutionsInners">
                <div className="SolutionICons">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/gavel.png`}
                    alt=""
                  />
                </div>
                <div className="SolutionInnerTitle">
                  <h4>
                    Automated Compliance and <br /> Enforcement
                  </h4>
                </div>
                <div className="SolutionInnerTitle">
                  <p>
                    NU Borders supports Government Agencies and the Private
                    sector with end to end compliance and enforcement automation
                    using cutting edge tools, including our BITE platform.
                  </p>
                </div>
                <div className="SoluinsBtn">
                  <MDBBtn href="/bite" className="ThemeBtn SmallBtn">
                    Read More <BtnArrowSmall />
                  </MDBBtn>
                </div>
              </div>
            </MDBCol>

            <MDBCol size="12" md="4" sm="12" className="mt-md-5 mt-0">
              <div className="SolutionsInners">
                <div className="SolutionICons">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/brain.png`}
                    alt=""
                  />
                </div>
                <div className="SolutionInnerTitle">
                  <h4>
                    Data Management, Analytics <br /> and Artificial
                    Intelligence
                  </h4>
                </div>
                <div className="SolutionInnerTitle">
                  <p>
                    Data is a very valuable asset to any organization. How it is
                    managed and analyzed is critical to the success of any
                    organization. NU Borders leverages Cloud infrastructure,
                    advanced analytics...
                  </p>
                </div>
                <div className="SoluinsBtn">
                  <MDBBtn href="/service" className="ThemeBtn SmallBtn">
                    Read More <BtnArrowSmall />
                  </MDBBtn>
                </div>
              </div>
            </MDBCol>

            <MDBCol size="12" md="4" sm="12">
              <div className="SolutionsInners">
                <div className="SolutionICons">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/idea.png`}
                    alt=""
                  />
                </div>
                <div className="SolutionInnerTitle">
                  <h4>
                    Custom IT Services and <br /> Solution
                  </h4>
                </div>
                <div className="SolutionInnerTitle">
                  <p>
                    Delivering tailored IT and data solutions including
                    architecture design and support, system integration, and Big
                    Data analytics for private companies and government
                    agencies.
                  </p>
                </div>
                <div className="SoluinsBtn">
                  <MDBBtn href="/service" className="ThemeBtn SmallBtn">
                    Read More <BtnArrowSmall />
                  </MDBBtn>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <section className="bussiness_ands bgTheme py-5 position-relative">
        <MDBContainer>
          <MDBRow className="align-items-center">
            <MDBCol size="12" md="6" sm="12">
              <div className="Bussiness_Banner">
                <img
                  src={`${process.env.PUBLIC_URL}/images/BusBanner.png`}
                  alt=""
                />
              </div>
            </MDBCol>
            <MDBCol>
              <div className="BusinessData pl-md-3 pl-0">
                <h1>
                  Business and <br /> Trade Intelligence
                </h1>

                <div className="BusinessPera">
                  <p>
                    Our Business Intelligence for Trade Enforcement (BITE)
                    platform provides cost effective and easy-to-use software
                    products that allow companies and organizations to monitor,
                    identify and mitigate risk in their supply chain, and remain
                    on the right side of trade compliance.
                  </p>
                </div>

                <div className="BtnsBusiness">
                  <a
                    href="https://bitedata.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MDBBtn className="ThemeBtn WhiteBtn">Go to Bite</MDBBtn>
                  </a>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <div className="rignround">
          <img src={`${process.env.PUBLIC_URL}/images/roundring.png`} alt="" />
        </div>
      </section>

      <section className="position-relative py-5 bgTheme z-0">
        <div className="rignroundleft">
          <img src={`${process.env.PUBLIC_URL}/images/roundring.png`} alt="" />
        </div>
        <MDBContainer>
          <MDBRow>
            <MDBCol size="12" md="6" sm="12">
              <div className="intrested-img-info">
                <img
                  src={`${process.env.PUBLIC_URL}/images/ThinkstockPhotos.png`}
                  alt=""
                />
              </div>
            </MDBCol>

            <MDBCol size="12" md="6" sm="12">
              <div className="interested">
                <h1>
                  Interested in <br /> Learning More?
                </h1>
                <Link to="/contact">
                  {" "}
                  <MDBBtn className="ThemeBtn WhiteBtn">
                    Contacts Us
                  </MDBBtn>{" "}
                </Link>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </React.Fragment>
  );
};
export default Home;
