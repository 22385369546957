import React, { useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import { Link } from "react-router-dom";

const Bite = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <section className="py-md-5 py-4 position-relative mt-sm-70">
        <div className="backImg">
          <img src={`${process.env.PUBLIC_URL}/images/bitebar.png`} alt="" />
        </div>
        <MDBContainer>
          <MDBRow>
            <MDBCol size="12" sm="12" md="12" lg="12" className="mb-4 mb-md-0">
              <div className="biteLogo" style={{ width: "50%" }}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/newbitelogo.png`}
                  alt=""
                />
              </div>
              <div className="BitePeras mt-3">
                <h1 style={{ fontWeight: 500 }}>
                  Trade Data. AI. Expert Verified.
                </h1>
                <p>
                  BITE is built for trade compliance and investigative
                  organizations, allowing for rapid identification and screening
                  for indicators of illicit trade. Our AI platform analyzes
                  billions of data points to generate finished intelligence and
                  automate risk-based scoring.
                </p>
                <p>
                  BITE can provide value to all sizes of organizations—from
                  small business importers/exporters to large defense companies
                  and Government agencies. Our tools cover all levels of users
                  with different needs.
                </p>
              </div>
              <div className="BtnGroups mt-4">
                <a
                  href="https://bitedata.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <MDBBtn className="ThemeBtn Bitewidth">
                    learn more{" "}
                  </MDBBtn>{" "}
                </a>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <section className="py-md-5 py-4">
        <MDBContainer>
          <MDBRow>
            <MDBCol size="12">
              <div className="OnlyImg">
                <img
                  src={`${process.env.PUBLIC_URL}/images/biteproducts.png`}
                  alt=""
                />
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <section className="position-relative py-5 z-0">
        <div className="rignroundleft">
          <img src={`${process.env.PUBLIC_URL}/images/Ellipse.png`} alt="" />
        </div>
        <MDBContainer>
          <MDBRow>
            <MDBCol size="12" md="6" sm="12">
              <div className="intrested-img-info BackBorder">
                <img
                  src={`${process.env.PUBLIC_URL}/images/ThinkstockPhotos.png`}
                  alt=""
                />
              </div>
            </MDBCol>

            <MDBCol size="12" md="6" sm="12">
              <div className="interested backChnage">
                <h1>Want to Learn More? </h1>
                <p>
                  Sign up for our quarterly newsletters <br></br> and webinars
                </p>
                <Link to="/contact">
                  {" "}
                  <MDBBtn className="ThemeBtn">Contact Us</MDBBtn>{" "}
                </Link>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </React.Fragment>
  );
};

export default Bite;
