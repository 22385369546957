import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

const Stars3 = () => {
  return (
    <>
      <MDBContainer>
        <div className="stars3">
          <section></section>
          <section>
            <MDBRow className="stars3header">
              <MDBCol size="8" lg="4" className="stars3">
                <img
                  object
                  src={`${process.env.PUBLIC_URL}/images/stars_III_final.jpg`}
                  alt=""
                />
              </MDBCol>
            </MDBRow>
            <br />
            <MDBRow>
              <MDBCol>
                <h3>We are SBA 8(a) certified.</h3>
                <a href="https://www.gsa.gov/stars3">www.gsa.gov/stars3</a>
              </MDBCol>
            </MDBRow>
            <br />
            <MDBRow>
              <MDBCol>
                <p>8(a) STARS III Contract Number: 47QTCB22D0303 </p>
                <p>DUNS: 080007465</p>
                <p>UEI Number: U8BKKE6QD8S4</p>
                <p>CAGE Code: 7HGS6</p>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <h4>Program Manager contact information:</h4>
                <p>Priti Patel</p>
                <p>
                  Email:{" "}
                  <a href={"mailto:stars3@nuborders.com"}>
                    stars3@nuborders.com
                  </a>
                </p>
                <p>
                  Phone: <a href="tel:240-750-5825">240-750-5825</a>
                </p>
              </MDBCol>
            </MDBRow>
          </section>
        </div>
      </MDBContainer>
    </>
  );
};

export default Stars3;

// <MDBCol size="12" md="6">
// <div className="d-flex justify-content-center">
//   <h3>SBA 8(a) certified</h3>
// </div>
// </MDBCol>
