import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MDBRow, MDBCol, MDBBtn, MDBContainer } from "mdbreact";

class HIKChinaBlog extends Component {
  state = {
    modal: false,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    return (
      <React.Fragment>
        <section className="Blog-Banner BlogDetails-Banner">
          <MDBContainer className="Blog-Title">
            <MDBRow>
              <MDBCol size="12">
                <div className="BannerTitle">
                  <h1>
                    Sensitive Technology Trade - HIK Group (China) Analysis
                  </h1>
                </div>

                <div className="Statusbar">
                  <div className="Timing">
                    <img src="" alt="" />
                    <h6>06/14/2023</h6>
                  </div>

                  <div className="Timing">
                    <img src="" alt="" />
                    <h6>Analytical Report</h6>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <div className="Banner">
            <img
              src={`${process.env.PUBLIC_URL}/images/HIKChina.jpeg`}
              className="banner-bg"
              alt=""
            />
          </div>
        </section>
        <section className="Blogs-Description">
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12">
                <div className="Description">
                  <div>
                    <p>
                      <strong>Executive Summary:</strong>
                    </p>
                    <p>
                      U.S. officials have estimated that China steals up to $600
                      billion of American intellectual property annually. This
                      theft endangers the jobs of more than 45 million Americans
                      who work in IP-intensive industries, which account for
                      more than $6.5 trillion in economic output.
                    </p>
                    <p>
                      Our analysis shows that HIK Technology Group (China) are
                      currently active in the sensitive technology space - we
                      also identified them as submitting a patent to the US
                      Patent Office (US PTO) and have identified shipments of
                      their products to multiple destinations around the world.
                    </p>
                    <p>
                      Companies should be wary of these transhipment points, and
                      violations / penalties that could ensue from US
                      enforcement agencies, should US companies be found to be
                      transacting/purchasing goods from watchlisted companies.
                    </p>
                    <div style={{ marginTop: "20px" }}>
                      <p>
                        <strong>Introduction</strong>
                      </p>
                      <p>
                        For the last several years, the U.S. government has been
                        developing policies and regulatory controls for
                        military, dual use and emerging technologies critical to
                        its national and economic security. This process has
                        been challenging from a policy perspective due to the
                        intangible nature of technology which does not easily
                        align with traditional regulatory approaches and
                        established control systems. The requirement for
                        effective technology controls while simultaneously
                        supporting U.S. IT innovation and competitive commercial
                        environment has become increasingly urgent in the
                        context of the U.S.-China power competition and China’s
                        aggressive theft of technology in support of its
                        national security objectives.
                      </p>
                      <p>
                        This month, the BITE team reports on some of our work
                        done to identify high-risk entities we have uncovered in
                        our data, the commodities they manufacture, and their
                        direct customers around the world. US companies should
                        be wary of receiving goods from watchlisted companies,
                        even if not received directly from the watchlisted
                        entity but through a transhipment point/entity.
                      </p>
                    </div>
                    <div>
                      <p>
                        <strong>Analytical Background</strong>
                      </p>
                      <p>
                        Technology designs are fundamentally more difficult to
                        control than physical military or dual use commodities
                        as they are “intangible” and their cybertheft and
                        transfers are inherently more difficult to define,
                        license and track.
                      </p>
                      <p>
                        Developing effective technology controls poses a
                        particularly complex challenge within the U.S. export
                        control regime. There are several licensing and
                        enforcement agencies with redundant responsibilities and
                        overlapping licensing jurisdictions; controlled
                        technologies may fall under the Department of State
                        (military) or Commerce (dual-use and military) or fall
                        under the review of the Committee on Foreign Investment
                        in the United States (CFIUS) or Commerce’s Certain
                        Emerging Technologies list which should not be confused
                        with the White House’s National Science and Technology
                        Council’s Critical and Emerging Technologies (CET) list.
                        An anticipated Executive Order for Outbound Investments
                        (reverse CFIUS) is expected to also cover technology
                        transfers.
                      </p>
                      <p>
                        While the U.S. has controlled military and dual use
                        technologies under existing export control regulations,
                        it is now expanding its control regime to regulate
                        critical and emerging technologies while at the same
                        time not adversely impacting its IT R&D competitive
                        environment.
                      </p>
                      <p>
                        U.S. technology control policy challenges are compounded
                        by the People’s Republic of China’s (PRC) aggressive,
                        ongoing state-directed technology theft programs such as
                        “Made in China 2025” and “Military-Civil Fusion.” These
                        efforts clearly show China’s commitment to IP
                        acquisition by any means necessary and their threat to
                        U.S. national and economic security.
                      </p>
                      <p>
                        PRC technology thefts endanger the U.S. job security of
                        more than 45 million Americans who work in IP-intensive
                        industries and account for more than $6.5 trillion in
                        economic output. The technologies sought by China are
                        often used for malign purposes, posing serious national
                        security risks to the U.S. and its allies.
                      </p>
                    </div>
                    <div>
                      <p>
                        <strong>Analytical Approach</strong>
                      </p>
                      <p>
                        Given the multi-agency requirements of technology
                        control policies and the diversity of stakeholders –
                        academia, IT sector, commercial sector, government – a
                        whole of government or whole of nation approach is
                        necessary to avoid implementation failure. In this
                        regard, NU Borders design hypothesis for this use case
                        was to query large-scale, diverse data sets - patent
                        information, trade data and denied parties lists – to
                        identify the intersection of the three domains of IP,
                        supply chains and malign actor actors.
                      </p>
                      <p>
                        Border Intelligence for Trade Enforcement (BITE)
                        analytic platform contains over 700 million
                        international manifest records, over 40 thousand patents
                        associated with critical and emerging technologies (CET)
                        identified through web scraping and 75 thousand denied
                        parties involved in illicit trade behavior.
                      </p>
                      <p>
                        The BITE team modeled the data across the platform to
                        identify Chinese entities on the U.S. Watch Lists, who
                        hold CET patents and are involved in the international
                        supply chain.
                      </p>
                    </div>
                    <div>
                      <p>
                        <strong>Use Case: Hikvision</strong>
                      </p>
                      <p>
                        Our analysis shows that Hangzhou Hikvision Digital
                        Technology Co., Ltd., (Hangzhou, China) is on the
                        Department of Commerce's entity list and Treasury's OFAC
                        NON-SDN list, holds U.S. patents for facial recognition
                        (a biometric technology) and has significant trade
                        transactions globally for camera shipments and related
                        equipment. Hikvision has been accused of enabling human
                        rights abuses by supplying the Chinese government with
                        cameras used in the repression of the Uyghurs, and are
                        currently watchlisted by the Department of Commerce and
                        Department of Treasury.
                      </p>
                      <p>
                        Additionally, our analysis shows multiple shipments
                        around the world for HIK Technology. A summary of the
                        data found in BITE includes the following:
                      </p>
                    </div>
                    <div>
                      <p>
                        <strong>Patent Data</strong>
                      </p>
                      <p>
                        US Patent Office (USPTO) data. Hangzhou Hikvision
                        Digital Technology Co., Ltd., a part of the HIK
                        Technology Group, are shown to have submitted for a
                        patent on “FACE LIVENESS DETECTION BASED ON NEURAL
                        NETWORK MODEL”, Patent No. 0200334450, on Oct 21, 2020.
                      </p>
                    </div>
                    <div>
                      <p>
                        <strong>Watchlists: </strong> &nbsp; HIK Technology
                        Group are on the following watchlists:
                      </p>
                      <p>
                        Entity List (EL) - Bureau of Industry and Security
                        <br />
                        Office of Foreign Assets Control (OFAC) - Non-SDN CMIC
                        List
                      </p>
                    </div>
                    <div>
                      <p>
                        <strong>Trade Data:</strong> &nbsp; The NU Borders
                        identified thousands of transactions by HIK Technology
                        to its overseas trade partners, including but not
                        limited to the following representative sample:
                      </p>
                      <p>
                        RECURSOS TECNOLOGICOS ABIERTOS TECHRESOURCES CIA LTDA,
                        Ecuador - Product Descriptions: Digital Cameras and
                        Camcorders, Video Intercom Screen, CARRIER CURRENT
                        TELECOMMUNICATION DEVICES OR DIGITAL TELECOMMUNICATION
                        <br />
                        <br />
                        MACROQUIL, Ecuador - Product Descriptions: NVR 16 CH,
                        Digital Cameras and Camcorders, DECODER 16 CH
                        <br />
                        <br />
                        JABIL CIRCUIT INDIA PRIVATE, India - Product
                        Descriptions: 497-0532015 3.7MM CAMERA MODULE (IGCRD SR
                        NO.13)
                        <br />
                        <br />
                        PHUONG VIET DISTRIBUTION, Vietnam - Product Description:
                        Camera recorder image CCTV IDS 2CD7A46G0 IZHS 28 12MM C
                        does not have MMDS in 2016 2016 ND CP 53 2018 ND CP Lens
                        28 12mm Brand Hikvision New100, IDS 7208HUHI M2 S
                        recorder has 08 channels including sources and mice
                        brands Hikvision new100
                        <br />
                        <br />
                        LINACO VIET NAM TECHNOLOGY DEVELOPMENT COMPANY, Vietnam
                        - Product Description: Webcam Hikvision DS UC8 no memory
                        card slot 1080p HD resolution 3 6mm lens Manufacturer
                        HikVision 100 new goods
                      </p>
                    </div>
                    <div>
                      <p>
                        <strong>Conclusion</strong>
                      </p>
                      <p>
                        U.S. technology policies and regulations such as CET are
                        complex, cut across several agencies’ responsibilities
                        and have a significant impact on a full spectrum of
                        stakeholders within the government and private sector.
                        As policies are developed to protect technology theft
                        and forced transfers, decision-makers should consider
                        leveraging data from multiple agencies and
                        private-sector and applying advanced data analytic
                        approaches to identify high-risk entities and their
                        behavior as demonstrated by the Hikvision use case.
                      </p>
                      <p>
                        Given the intangible nature of technology transfers and
                        inherent challenges in identifying violations, data
                        analytics paired with large-scale open-source data sets
                        such as patent and manifest records provide a pragmatic
                        solution for 1) identifying critical and emerging
                        technologies 2) identifying those at risk for cybertheft
                        and illicit transfers 3) while supporting innovative and
                        collaborative marketplace for IT advancements.
                      </p>
                      <p>
                        While our analysis in this article did not provide
                        details on shipments from transhipment points like
                        Vietnam and India to the US, companies should be wary of
                        these transhipment points and ensure sensitive
                        technology from China is not being imported into the US,
                        thereby reducing demand for US-manufactured goods.
                      </p>
                      <p>
                        Want a stronger compliance profile for your company? Set
                        up a discussion and demo of BITE by emailing us at
                        &nbsp;
                        <a href="mailto:bite@nuborders.com">
                          bite@nuborders.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <Link to="/blog">
                    <MDBBtn className="ThemeBtn">Back to blogs</MDBBtn>
                  </Link>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </React.Fragment>
    );
  }
}

export default HIKChinaBlog;
