import React, { useEffect } from "react";
import { MDBRow, MDBCol, MDBBtn, MDBContainer } from "mdbreact";
import emailjs from "emailjs-com";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_656rdhb",
        "template_5efa2gt",
        e.target,
        "user_w9JtO606xKn4FqDkiDSGJ"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <React.Fragment>
      <section className="contactpage my-5">
        <MDBContainer>
          <MDBRow className="Contact-Info">
            <MDBCol size="12" md="6" sm="12" className="Img-Col">
              <div className="contact-BannerImage">
                <img
                  src={`${process.env.PUBLIC_URL}/images/contact-bg.jpg`}
                  alt=""
                  className=""
                />
              </div>
            </MDBCol>
            <MDBCol size="12" md="6" sm="12">
              <div className="rightsideContect ContactDetails">
                <h1>Get in Touch</h1>
                <p>
                  Looking for more information about NU Borders and our{"   "}
                  services? Fill the out form and we’ll get back to you soon.
                </p>

                <div className="InnerContactForm mt-4">
                  <form onSubmit={sendEmail}>
                    <MDBRow>
                      <MDBCol size="6" md="6" sm="6">
                        <div className="form-group">
                          <label htmlFor="example1">First Name</label>
                          <input
                            type="text"
                            name="from_fname"
                            id="example1"
                            placeholder=""
                            className="form-control form-control-lg"
                          />
                        </div>
                      </MDBCol>
                      <MDBCol size="6" md="6" sm="6">
                        <div className="form-group">
                          <label htmlFor="example2">Last Name</label>
                          <input
                            type="text"
                            name="from_lname"
                            id="example2"
                            placeholder=""
                            className="form-control form-control-lg"
                          />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol size="6" md="6" sm="6">
                        <div className="form-group">
                          <label htmlFor="example1">Email Address</label>
                          <input
                            type="text"
                            name="reply_to"
                            id="example1"
                            placeholder=""
                            className="form-control form-control-lg"
                          />
                        </div>
                      </MDBCol>
                      <MDBCol size="6" md="6" sm="6">
                        <div className="form-group">
                          <label htmlFor="example2">Phone Number</label>
                          <input
                            type="text"
                            name="phone"
                            id="example2"
                            placeholder=""
                            className="form-control form-control-lg"
                          />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol size="12" md="12" sm="12">
                        <div className="form-group">
                          <label htmlFor="example2">Message</label>
                          <textarea
                            rows="4"
                            name="message"
                            id="example2"
                            placeholder=""
                            className="form-control form-control-lg"
                          ></textarea>
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol size="12" md="12" sm="12">
                        <MDBBtn className="ThemeBtn" type="submit">
                          SEND MESSAGE
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </form>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </React.Fragment>
  );
};

export default Contact;
