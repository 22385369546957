import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import {
  LinkDin,
  Twitter,
  InstaGram,
  YouTube,
  PhoneIcon,
  MailIcon,
} from "../Icons";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <React.Fragment>
      <MDBFooter color="white" className="pt-4 pb-0 py-md-5">
        <MDBContainer className="text-center text-md-left">
          <MDBRow>
            <MDBCol md="3" className="Footer-Col">
              <div className="Foo01">
                <img
                  src={`${process.env.PUBLIC_URL}/images/MainLogo.png`}
                  height="25"
                  alt=""
                  style={{ maxWidth: "100%" }}
                />
                <p>
                  Custom Solutions <br></br> Unbounded Innovative Ideas{" "}
                  <br></br> Automated Compliance
                </p>
              </div>
            </MDBCol>
            <MDBCol md="3" className="d-flex justify-content-center Footer-Col">
              <div className="LinksInfos">
                <h5 className="title">Information</h5>
                <ul>
                  <li className="list-unstyled">
                    <Link to="/bite">BITE</Link>
                  </li>
                  <li className="list-unstyled">
                    <Link to="/service">Services</Link>
                  </li>
                  <li className="list-unstyled">
                    <Link to="/client">Clients</Link>
                  </li>
                  {/* <li className="list-unstyled">
                    <Link to="/blog">Blog</Link>
                  </li> */}
                </ul>
              </div>
            </MDBCol>

            <MDBCol md="3" className="d-flex justify-content-center Footer-Col">
              <div className="LinksInfos">
                <h5 className="title">our company</h5>
                <ul>
                  <li className="list-unstyled">
                    <Link to="/team">Team</Link>
                  </li>
                  <li className="list-unstyled">
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </MDBCol>

            <MDBCol
              md="3"
              className="d-flex justify-content-center Footer-Col mb-0"
            >
              <div className="LinksInfos">
                <h5 className="title">address</h5>
                <p>Washington DC</p>
                <p> Boston, MA</p>
                <p>
                  <PhoneIcon />{" "}
                  <a href="tel:617-297-8816" style={{ color: "black" }}>
                    617-297-8816
                  </a>
                </p>
                <p>
                  <MailIcon />{" "}
                  <a
                    href={"mailto:info@nuborders.com"}
                    style={{ color: "black" }}
                  >
                    info@nuborders.com
                  </a>
                </p>
              </div>
            </MDBCol>

            <MDBCol size="12" className="Social-Col">
              <div className="socialicons">
                <ul>
                  <li>
                    <a href="https://www.linkedin.com/company/nu-borders-llc/mycompany/?viewAsMember=true">
                      <LinkDin />
                    </a>
                  </li>
                  <li>
                    {" "}
                    <Link to="#">
                      <Twitter />
                    </Link>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/nuborders/?hl=en">
                      <InstaGram />
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="https://www.youtube.com/channel/UC__zV_JwelrPujGJbQQBXtw">
                      <YouTube />
                    </a>{" "}
                  </li>
                </ul>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBFooter>
      <div className="footer-copyright text-center py-2">
        <MDBContainer>
          <p>Copyright © 2021 NU Borders, LLC All rights reserved.</p>
        </MDBContainer>
      </div>
    </React.Fragment>
  );
};

export default Footer;
