import React, { useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
// import { BtnArrowSmall } from "./Icons";
const Client = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <section className="py-md-5 py-4 mt-sm-70">
        <MDBContainer>
          <MDBRow>
            <MDBCol size="12">
              <div className="OurClients">
                <div className="Col-Box-2">
                  <div className="SubHeding">
                    <h1>
                      Meet <br /> Our Clients
                    </h1>
                    <p>
                      We appreciate all our clients, both past and present, and
                      look forward to growing our customer base while still
                      providing dedicated, high quality products and support.
                    </p>
                  </div>
                </div>

                <div className="Col-Box">
                  <div className="InnerLogoPart">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/dept-of-state.png`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="Col-Box">
                  <div className="InnerLogoPart">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/dept-of-homeland.png`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="Col-Box">
                  <div className="InnerLogoPart">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/dept-of-defense.png`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="Col-Box">
                  <div className="InnerLogoPart">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/PDC_Logo_Web.png`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="Col-Box">
                  <div className="InnerLogoPart">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/intelligence-1.png`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="Col-Box">
                  <div className="InnerLogoPart">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/Security_Investigations_special_agent.svg.png`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="Col-Box">
                  <div className="InnerLogoPart">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/DeptofJustice.png`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="Col-Box">
                  <div className="InnerLogoPart">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/CACI logo.png`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="Col-Box">
                  <div className="InnerLogoPart">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/White-Logo-Small.png`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="Col-Box">
                  <div className="InnerLogoPart">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/GSA_Icon.png`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="Col-Box">
                  <div className="InnerLogoPart">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/MIIS_Logo.png`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="Col-Box">
                  <div className="InnerLogoPart">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/culmen.png`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="Col-Box">
                  <div className="InnerLogoPart">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/Accenture_logo_logotype.png`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="Col-Box">
                  <div className="InnerLogoPart">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/2018-WL_Logo.png`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="Col-Box">
                  <div className="InnerLogoPart">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/decipher-1.png`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="Col-Box">
                  <div className="InnerLogoPart">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/booz-allen-hamilton-logo1.png`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="Col-Box">
                  <div className="InnerLogoPart">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/SBALogo.jpg`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="Col-Box">
                  <div className="InnerLogoPart">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/CGILogo.png`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <section className="bg-F5F5F5 position-relative overflow-hidden">
        <div className="rignroundright">
          <img src={`${process.env.PUBLIC_URL}/images/roundring.png`} alt="" />
        </div>
        <MDBContainer fluid>
          <MDBRow middle className="align-items-center">
            <MDBCol size="12" sm="12" md="4" lg="4" className="p-0">
              <div className="howtoget">
                <img
                  src={`${process.env.PUBLIC_URL}/images/avel-chuklanov.png`}
                  alt=""
                />
              </div>
            </MDBCol>
            <MDBCol size="12" sm="12" md="8" lg="8">
              <div className="px-md-5 px-0 HowToGetToUs">
                <h1>
                  How To Get <br /> To Us{" "}
                </h1>
                <div className="listStyles">
                  <ul>
                    <li>
                      GSA STARS III <a href="/stars3">Award</a>{" "}
                    </li>
                    <li>
                      GSA MAS IT{" "}
                      <a
                        href={`https://www.gsaelibrary.gsa.gov/ElibMain/contractorInfo.do?contractNumber=47QTCA18D007N&contractorName=NU+BORDERS+LLC&executeQuery=YES`}
                      >
                        Schedule 70
                      </a>
                    </li>
                    <li>
                      BITE Products and Services available via{" "}
                      <a
                        href={`https://www.gsaadvantage.gov/advantage/ws/search/advantage_search?searchType=1&q=10:0NU%20Borders&s=0&c=25`}
                      >
                        GSA Advantage
                      </a>
                    </li>
                    <li>
                      We are also on multiple contract vehicles through our
                      partners including CIO-SP3, SITE III, and STARS
                    </li>
                  </ul>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <section className="pt-md-5 pb-md-3 pt-4 pb-0">
        <MDBContainer>
          <MDBRow>
            <MDBCol size="12">
              <div className="Ittle_Headings">
                <h2>NU Borders NAICS codes are as follows:</h2>
              </div>
            </MDBCol>
          </MDBRow>

          <MDBRow className="mt-4">
            <MDBCol size="12" sm="12" md="4">
              <div className="InnerDetails">
                <h2>541512</h2>
                <p>Computer Systems Design Services</p>
              </div>
            </MDBCol>
            <MDBCol size="12" sm="12" md="4">
              <div className="InnerDetails">
                <h2>518210</h2>
                <p>Data Processing, Hosting, and Related Services</p>
              </div>
            </MDBCol>

            <MDBCol size="12" sm="12" md="4">
              <div className="InnerDetails">
                <h2>541690</h2>
                <p>Other Scientific and Technical Consulting Services</p>
              </div>
            </MDBCol>
            <MDBCol size="12" sm="12" md="4">
              <div className="InnerDetails">
                <h2>541511</h2>
                <p>Computer Systems Design Services</p>
              </div>
            </MDBCol>
            <MDBCol size="12" sm="12" md="4">
              <div className="InnerDetails">
                <h2>541715</h2>
                <p>
                  Research and Development in the Physical, Engineering, and
                  Life Sciences
                </p>
              </div>
            </MDBCol>

            <MDBCol size="12" sm="12" md="4">
              <div className="InnerDetails">
                <h2>511210</h2>
                <p>Software Publishers</p>
              </div>
            </MDBCol>
            <MDBCol size="12" sm="12" md="4">
              <div className="InnerDetails">
                <h2>541990</h2>
                <p>
                  All Other Professional, Scientific, and Technical Services
                </p>
              </div>
            </MDBCol>
            <MDBCol size="12" sm="12" md="4">
              <div className="InnerDetails">
                <h2>541519</h2>
                <p>Other Computer Related Services</p>
              </div>
            </MDBCol>

            <MDBCol size="12" sm="12" md="4">
              <div className="InnerDetails">
                <h2>611420</h2>
                <p>Computer Training</p>
              </div>
            </MDBCol>
            <MDBCol size="12" sm="12" md="4">
              <div className="InnerDetails">
                <h2>541611</h2>
                <p>
                  Administrative Management and General Management Consulting
                  Services
                </p>
              </div>
            </MDBCol>
            <MDBCol size="12" sm="12" md="4">
              <div className="InnerDetails">
                <h2>611430</h2>
                <p>Professional and Management Development Training</p>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </React.Fragment>
  );
};

export default Client;
