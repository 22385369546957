export const UseCasesData = [
  {
    title: "Customs Enforcement",
    pdfSrc:
      "https://s3.amazonaws.com/nuborders.com/static/media/02-NB-BITE-One-Pager_Customs-Enforcement+(1).pdf",
    imgSrc: `${process.env.PUBLIC_URL}/images/UseBoxBottoms.png`,
  },
  {
    title: "Wildlife Trafficking",
    pdfSrc:
      "https://s3.amazonaws.com/nuborders.com/static/media/02-NB-BITE-One-Pager_Wildlife-Trafficking+(1).pdf",
    imgSrc: `${process.env.PUBLIC_URL}/images/UseBoxBottoms02.png`,
  },
  {
    title: "Counter Proliferation",
    pdfSrc:
      "https://s3.amazonaws.com/nuborders.com/static/media/02-NB-BITE-One-Pager_Counter-Proliferation+(1).pdf",
    imgSrc: `${process.env.PUBLIC_URL}/images/UseBoxBottoms03.png`,
  },
  {
    title: "Intellectual Property Rights",
    pdfSrc:
      "https://s3.amazonaws.com/nuborders.com/static/media/02-NB-BITE-One-Pager_Intellectual+Property+(1).pdf",
    imgSrc: `${process.env.PUBLIC_URL}/images/UseBoxBottoms04.png`,
  },
  {
    title: "Supply Chain Compliance",
    pdfSrc:
      "https://s3.amazonaws.com/nuborders.com/static/media/02-NB-BITE-One-Pager_Supply-Chain+(1).pdf",
    imgSrc: `${process.env.PUBLIC_URL}/images/UseBoxBottoms05.png`,
  },
  {
    title: "Anti-Dumping (AD/CVD)",
    pdfSrc:
      "https://s3.amazonaws.com/nuborders.com/static/media/02-NB-BITE-One-Pager_Anti-Dumping+(1).pdf",
    imgSrc: `${process.env.PUBLIC_URL}/images/UseBoxBottoms06.png`,
  },
  {
    title: "Logistics and Tracking",
    pdfSrc:
      "https://s3.amazonaws.com/nuborders.com/static/media/02-NB-BITE-One-Pager_Logistics-and-Tracking+(2).pdf",
    imgSrc: `${process.env.PUBLIC_URL}/images/UseBoxBottoms07.png`,
  },
  {
    title: "Cyber Analytics",
    pdfSrc:
      "https://s3.amazonaws.com/nuborders.com/static/media/02-NB-BITE-One-Pager_Cyber-Analytics+(1).pdf",
    imgSrc: `${process.env.PUBLIC_URL}/images/UseBoxBottoms09.png`,
  },
];
