import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MDBRow, MDBCol, MDBBtn, MDBContainer } from "mdbreact";

class SanctionsEvasionBlog extends Component {
  state = {
    modal: false,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    return (
      <React.Fragment>
        <section className="Blog-Banner BlogDetails-Banner">
          <MDBContainer className="Blog-Title">
            <MDBRow>
              <MDBCol size="12">
                <div className="BannerTitle">
                  <h1>
                    Illicit Procurement Patterns by Russian Defense Sector
                  </h1>
                </div>

                <div className="Statusbar">
                  <div className="Timing">
                    <img src="" alt="" />
                    <h6>03/24/2023</h6>
                  </div>

                  <div className="Timing">
                    <img src="" alt="" />
                    <h6>Analytical Report</h6>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <div className="Banner">
            <img
              src={`${process.env.PUBLIC_URL}/images/BITE_Blog_post_April_2023.jpg`}
              className="banner-bg"
              alt=""
            />
          </div>
        </section>
        <section className="Blogs-Description">
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12">
                <div className="Description">
                  <p>
                    In this article we provide further insight into our analysis
                    of ROSOBORONEKSPORT’, a Russia’s only state-controlled
                    intermediary in the area of exports and imports of the
                    entire range of military and double-purpose products,
                    technologies and services, and methods its uses to evade
                    sanctinos.
                  </p>
                  <p>
                    BITE analysis shows several Russian companies utilizing
                    ROSOBORONEKSPORT’s corporate TAX ID for trade transactions,
                    to include LAZERSERVIS. ROSOBRON is an OFAC-listed denied
                    party, added
                    <strong> Significance of Analysis:</strong> In this report,
                    our analysis identifies ROSOBORONEKSPORT as using non-
                    sanctioned LAZERVIS as a ‘cut-out’ or alternative company to
                    transact trade and evade sanctions, sometimes known as
                    ‘sanctions busting’.
                  </p>
                  <div>
                    <p>
                      <u>
                        <strong>Summary of Suspicious Trade Pattern</strong>
                      </u>
                    </p>
                    <p>
                      Between January of 2019 and December of 2020, BITE
                      identified 183 trade transactions for LAZERSERVIS, a
                      Russian company based in Moscow. Although open-source
                      research identifies the TAX ID for LAZERSERVIS as
                      7731280660, 51 of those 183 transactions were conducted
                      using the Tax ID for ROSOBORONEKSPORT, 7718852163. Those
                      transactions, which originated in various places around
                      the globe, were valued collectively at over $5 Million
                      dollars. These transactions included commodities under
                      Chapters 84, 85 and 90 of the Harmonized System and
                      commodity descriptions to include, block of gyroscopic
                      sensors, pressure regulators and sensors.
                    </p>
                    <img
                      style={{ width: "100%" }}
                      src={`${process.env.PUBLIC_URL}/images/In-article_image.png`}
                      alt=""
                    />
                    <p>
                      <br />
                      <u>
                        <strong>Background</strong>
                      </u>
                    </p>
                    <p>
                      As the US and Western allies ratchet up sanctions and
                      enhance strategic trade controls, Russia’s military-
                      industrial and defense sectors must increasingly rely upon
                      transshipment methods and other illicit approaches to
                      procure critical components. Recovered Russian military
                      equipment in post-invasion Ukraine clearly indicates
                      Russia’s urgent need for{" "}
                      <a
                        href="https://www.reuters.com/business/aerospace-defense/exclusive-russian-weapons-ukraine-powered-by-hundreds-western-parts-rusi-2022-08-08/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        critical foreign components
                      </a>{" "}
                      for military production. This supply-chain model
                      demonstrates an important strategic vulnerability for
                      Russia and opportunity to constrain its military
                      industrial sector.
                    </p>
                    <p>
                      Typical transshipment patterns include sourcing military
                      and dual-use equipment from North American and Western
                      suppliers through points in Asia, former Soviet Union
                      members, and the Middle East, to disguise the ultimate end
                      users of the controlled commodities. Additionally,
                      prohibited end-users in Russia will obfuscate Russian
                      consignee information to hide the true military end-use of
                      the shipments which often contain dual use items having
                      both commercial and military applications.
                    </p>
                  </div>
                  <div>
                    <p>
                      <u>
                        <strong>Analysis Methodology</strong>
                      </u>
                    </p>
                    <p>
                      NU Borders’ Business Intelligence for Trade Enforcement
                      (BITE) platform ingests hundreds of millions of
                      open-source trade transactions and other relevant data,
                      such as global watch lists and corporate information, and
                      analyzes that data using state-of-the-art AI and Machine
                      Learning tools and models. NU Borders team of data
                      scientists and enforcement SMEs continually interrogate
                      BITE data to reveal high- level procurement patterns down
                      to granular details of specific illicit transactions
                      associated with Russian military supply chains. NU Borders
                      will be publishing our findings monthly to inform the
                      global strategic trade community.
                    </p>
                    <p>
                      This month’s report focuses on a BITE-identified pattern
                      whereby Russian importers, who are not currently on watch
                      lists, are used as cutouts for denied Russian entities’
                      international trade transactions. In these cases,
                      non-sanctioned entities are the listed importer on the
                      shipment, but other identifying information - the denied
                      entity’s address, email, telephone and/or corporate ID -
                      are still listed in the trade transaction.
                    </p>
                    <p>
                      Lessons learned from the BITE data suggest international
                      enforcement officials and risk management operators should
                      include in their best-practices vetting of additional data
                      elements in international trade transactions beyond
                      company name, including all corporate identifiers
                      (address, phone, fax, email) and, critically, corporate
                      identification numbers.
                    </p>
                    <p>
                      This best practice is supported by several other BITE use
                      cases which reveal similar patterns of Russian cutouts
                      utilizing denied entities&#39; corporate IDs. Please note
                      these companies and other intermediaries (defined as
                      facilitating shipments/transshipments to denied entities
                      in Russia, Iran, DPRK and other prohibited regions and/or
                      end users but not captured on watch lists) are available
                      through BITE List subscription.
                    </p>
                  </div>
                  <div>
                    <p>
                      <u>
                        <strong>Results</strong>
                      </u>
                    </p>
                    <p>
                      This month’s post features Russian Designated Entity,
                      ROSOBORONEKSPORT OAO.
                    </p>
                  </div>
                  <div>
                    <p>
                      <u>
                        <strong>Designated Company:</strong>
                      </u>
                    </p>
                    <p>
                      ROSOBORONEKSPORT OAO
                      <br />
                      27 Stromynka Ul.
                      <br />
                      Moscow 107076 Russia
                      <br />
                      Registration ID: 1117746521452
                      <br />
                      Government Gazette Number: 56467052
                      <br />
                      Tax ID No: 7718852163 Russia
                      <br />
                      <a
                        href="https://sanctionssearch.ofac.treas.gov/Details.aspx?id=18782"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        US OFAC Listing
                      </a>
                    </p>
                  </div>
                  <div>
                    <p>
                      <u>
                        <strong>Reason for placement on the BITE LIST:</strong>
                      </u>
                    </p>
                    <p>
                      BITE analysis shows several Russian companies utilizing
                      ROSOBORONEKSPORT’s corporate TAX ID for trade
                      transactions, to include LAZERSERVIS.
                    </p>
                  </div>
                  <div>
                    <p>
                      <u>
                        <strong>Non-Sanctioned Associated Company:</strong>
                      </u>
                    </p>
                    <p>
                      LAZERSERVIS
                      <br />
                      Vereyskaya Street, D39
                      <br />
                      Moscow 121357 Russia
                      <br />
                      Tax ID: 7731280660 Russia
                      <br />
                      <a
                        href="https://www.list-org.com/company/78524"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.list-org.com/company/78524
                      </a>
                      <br />
                      <a
                        href="http://www.laserservice.ru/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        http://www.laserservice.ru/
                      </a>
                    </p>
                  </div>
                  <div>
                    <p>
                      <u>
                        <strong>Summary of Suspicious Trade Pattern</strong>
                      </u>
                    </p>
                    <p>
                      Between January of 2019 and December of 2020, BITE
                      identified 183 trade transactions for LAZERSERVIS, a
                      Russian company based in Moscow. Although open-source
                      research identifies the TAX ID for LAZERSERVIS as
                      7731280660, 51 of those 183 transactions were conducted
                      using the Tax ID for ROSOBORONEKSPORT, 7718852163. Those
                      transactions, which originated in various places around
                      the globe, were valued collectively at over $5 Million
                      dollars. These transactions included commodities under
                      Chapters 84, 85 and 90 of the Harmonized System and
                      commodity descriptions to include, block of gyroscopic
                      sensors, pressure regulators and sensors.
                    </p>
                  </div>
                  <div>
                    <p>
                      <u>
                        <strong>Analysis Support</strong>
                      </u>
                    </p>
                    <p>
                      183 international trade transactions for LASER SERVICE in
                      BITE
                      <br />
                      51 with Laser Service using Rosboroneksport Tax id:
                      7718852163
                      <br />
                      Cargo value for 51 hits is $5,907,064
                    </p>
                    <p>
                      Legal address:
                      <br />
                      MOSCOW, VEREYSKAYA STREET, D 39
                    </p>
                    <p>
                      Shippers to LASER SERVICE:
                      <br />
                      Malaysia-listed exporting country
                      <br />
                      India-listed as origin country
                    </p>
                    <p>
                      List of shippers:
                      <br />
                      CORPORATION OF AVIATION TECHNOLOGICAL SYSTEMS SDN. BHD
                      <br />
                      HINDUSTAN AERONAUTIKS LTD
                      <br />
                      MINISTRY OF NATIONAL DEFENSE OF THE PEOPLE&#39;S
                      DEMOCRATIC REPUBLIC OF ALGERIA
                      <br />
                      MINISTRY OF DEFENSE OF THE SYRIAN ARAB REPUBLIC
                      <br />
                      THE PRESIDENT OF INDIA REPRESENTED BY THE MINISTRY OF
                      DEFENSE OF THE REPUBLIC OF INDIA
                      <br />
                      MINISTRY OF DEFENSE OF THE SYRIAN ARAB REPUBLIC ARMY
                      SUPPLY BUREAU
                    </p>
                    <p>
                      Commodity Descriptions:
                      <br />
                      Sensors, signaler of computer and power units
                      <br />
                      4.2 products
                      <br />
                      Transmitter
                      <br />
                      Gyroscopic sensor
                      <br />
                      Pressure regulators
                      <br />
                      BLOCK OF GYROSCOPIC SENSORS
                    </p>
                    <p>
                      Primarily HS Code chapters:
                      <br />
                      90
                      <br />
                      84
                      <br />
                      85
                      <br />
                      PRESSURE REGULATOR VALVE EXHAUST
                    </p>
                    <p>
                      Addresses and Tax IDs for Laser Service
                      <br />
                      <a
                        href="https://www.list-org.com/company/78524"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.list-org.com/company/78524
                      </a>
                      <br />
                      <a
                        href="https://sbis.ru/contragents/7731280660/773101001"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://sbis.ru/contragents/7731280660/773101001
                      </a>
                    </p>
                    <div>
                      <p>
                        <strong>
                          АО &quot;ЛАЗЕРСЕРВИС&quot; (laserservis cyrillic)
                        </strong>
                      </p>
                    </div>
                    <div>
                      <p>
                        <strong>Quick reference</strong>
                      </p>
                      <p>
                        Laserservice, JSC is registered at 121357, Moscow, st.
                        Vereiskaya, 39. General director of the organization
                        JOINT-STOCK COMPANY &quot;LAZERSERVIS&quot; Andrey
                        Alexandrovich Pavlov. The main activity of the company
                        is the Repair and maintenance of aircraft, including
                        spacecraft. Also, Laserservice, JSC works in 17 more
                        areas. The size of the authorized capital is 10,300,000
                        rubles.
                        <br />
                        The organization has 1 established company. Has 5
                        licenses.
                        <br />
                        Laserservice, JSC took part in 10 auctions and won 10 of
                        them. The main customer is 514 Arz, JSC. In courts, the
                        organization won 33% of cases as a plaintiff
                        <br />
                        JOINT STOCK COMPANY &quot;LAZERSERVIS&quot; was assigned
                        TIN 7731280660 , KPP 773101001 , OGRN 1047731013857 ,
                        OKPO 55196964
                        <br />
                        Valid from 14.07.2004
                        <br />
                        <a
                          href="https://sbis.ru/contragents/7731280660/773101001"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://sbis.ru/contragents/7731280660/773101001
                        </a>
                      </p>
                    </div>
                    <div>
                      <p>
                        <u>
                          <strong>Conclusion: </strong>
                        </u>
                      </p>
                      <p>
                        BITE investigative analysis identifies non-sanctioned
                        company (LASERSERVIS) being used as a ‘cut-out’ for a
                        sanctioned company. LASERSERVIS was involved in 183
                        total trade transactions in BITE, but the
                        ROSBORONEXPORT  TAX ID was listed in 51 of those
                        transactions.
                      </p>
                      <p>
                        In the vast majority of their transactions in BITE,
                        their own tax id (7731280660) was listed in the data.
                        However, in 51 of their 183 transactions, their TAX ID
                        was listed as 7718852163, which is registered to
                        ROSBORONEKSPORT, a sanctioned company.
                      </p>
                      <p>
                        So this is potentially a way for ROSBORONEXPORT to have
                        goods, which are actually destined for them, shipped to
                        LASERSERVIS, to avoid detection/seizure and to evade
                        sanctions, otherwise known as ‘sanctions-busting’.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <Link to="/blog">
                    <MDBBtn className="ThemeBtn">Back to blogs</MDBBtn>
                  </Link>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </React.Fragment>
    );
  }
}

export default SanctionsEvasionBlog;
