import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MDBRow, MDBCol, MDBBtn, MDBContainer } from "mdbreact";

class PDCPressReleaseBlog extends Component {
  state = {
    modal: false,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    return (
      <React.Fragment>
        <section className="Blog-Banner BlogDetails-Banner">
          <img src="" alt="" />
          <MDBContainer className="Blog-Title">
            <MDBRow>
              <MDBCol size="12">
                <div className="BannerTitle">
                  <h1>
                    NU Borders' BITE Platform uses Advanced Analytics to Support
                    Anti-Poaching in Southern Africa
                  </h1>
                </div>

                <div className="Statusbar">
                  <div className="Timing">
                    <img src="" alt="" />
                    <h6>08/19/2021</h6>
                  </div>

                  <div className="Timing">
                    <img src="" alt="" />
                    <h6>Analytics</h6>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>

          <div className="Banner">
            <img
              src={`${process.env.PUBLIC_URL}/images/pexels-marc-mueller-380769.png`}
              className="banner-bg"
              alt=""
            />
          </div>
        </section>

        <section className="Blogs-Description">
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12">
                <div className="Description">
                  <p>
                    NU Borders has partnered with the{" "}
                    <a href="https://www.painteddog.org/">
                      Painted Dog Conservation
                    </a>
                    (PDC), a non-profit organization located in Hwange National
                    Park, Zimbabwe to provide PDC with access to the Business
                    Intelligence for Trade Enforcement (BITE) platform – an
                    analytical tool suite to help analyze and track wildlife,
                    look at the movement patterns and trends of the animals and
                    gain better insight into how PDC resources can be optimized
                    to support anti-poaching efforts.{" "}
                  </p>
                  <p>
                    NU Borders is a Data Science and Data Analytics company
                    focused on helping enforcement and compliance operators
                    identify threats and illicit activity in their supply chain
                    across a variety of domains including customs enforcement,
                    intellectual property theft, wildlife trafficking, human
                    trafficking and money laundering. In 2018, NU Borders
                    created the Business Intelligence for Trade Enforcement
                    (BITE) platform, a Software as a Service (SaaS) technology,
                    to assist our clients with big data ingestion, analysis of
                    data using cutting edge tools and using the output to make
                    informed decisions. BITE contains large, open-source
                    commercial trade datasets and screening lists, made
                    available to users across all domains. NU Borders' BITE
                    platform includes cutting-edge visualization tools, an
                    integrated workflow management system and access to hands-on
                    training with our Data Scientists and Subject Matter
                    Experts.
                  </p>
                  <div>
                    <p>
                      The PDC was founded in 1992 for the protection of African
                      Wild Dogs' and their efforts have increased Zimbabwe's
                      wild dog population since its creation. Rangers engage
                      with and incorporate local communities to provide
                      educational resources and outreach programs for the sole
                      purpose of creating an environment where the wild dog
                      population can thrive. PDC's operations include daily
                      patrols from members of the Anti-Poaching Unit to provide
                      a blanket of protection from illegal poaching, and daily
                      monitoring of study packs provides data on the health
                      status of dogs and their packs, as well as the PDC run
                      Rehabilitation Facility where injured or orphaned dogs are
                      treated before safely releasing them to the wild.{" "}
                    </p>
                    <p>
                      Big Data analytics and real-time analyses are critical to
                      conservation groups who are focused on protection of
                      species and their habitats. Through analyses and
                      visualization of large datasets in rapid time, rangers can
                      quickly see where resources can be better allocated to
                      assist their efforts and day-to-day operations for
                      conservation efforts. Advanced analytics help
                      conservationists quickly analyze large, varied datasets
                      including GPS coordinates from dog collars, and can be
                      further enhanced by overlaying environmental data which
                      provides a holistic view of the habitat.
                    </p>
                  </div>
                  <div>
                    <p>
                      NU Borders delivered hands-on training of the BITE
                      platform to PDC rangers, in July 2021. This session
                      enhanced PDC’s analytical capabilities and focused on data
                      ingestion and cleaning of wildlife trafficking data; how
                      to create a dashboard with visualizations using the BITE
                      tool suite; and technical support of the BITE platform. NU
                      Borders is proud to continue our partnership with the PDC
                      to enhance their advanced analytical capabilities in order
                      to protect African Wild Dogs' and their habitats.
                    </p>
                  </div>
                  <p></p>
                  <div>
                    <img src="" alt="" />
                    <p></p>
                  </div>
                  <div>
                    <img src="" alt="" />
                    <p></p>
                    <p></p>
                  </div>
                </div>
                <div>
                  <Link to="/blog">
                    <MDBBtn className="ThemeBtn">Back to blogs</MDBBtn>
                  </Link>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </React.Fragment>
    );
  }
}

export default PDCPressReleaseBlog;
