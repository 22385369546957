import React from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import { Link } from "react-router-dom";
import SharkFinPDF from "./BlogDetails/PDFs/NUBO_SharkFin_Final.pdf";
import CoralPDF from "./BlogDetails/PDFs/NUBO_IWT_Coral_Final.pdf";
import WebinarInvitePDF from "./BlogDetails/PDFs/02-NB-Wildlife-Webinar-Invite.pdf";
import Apr22Vol4PDF from "./BlogDetails/PDFs/April_2022_Volume_4.pdf";
import Apr22Vol5PDF from "./BlogDetails/PDFs/April_2022_Volume_5.pdf";
import OligarchsBlog from "./BlogDetails/PDFs/01-NB-Oligarch-Blog.pdf";
import RussianEntityBlog from "./BlogDetails/PDFs/Trade-Transactions-Affiliated-with-Russian-Entity.pdf";

const Blog = () => (
  <React.Fragment>
    <section className="Blog-Banner">
      <MDBContainer className="Blog-Title">
        <MDBRow>
          <MDBCol size="12">
            <div className="BannerTitle">
              <h1>NU Borders' Blog</h1>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <div className="Banner">
        <img
          src={`${process.env.PUBLIC_URL}/images/BITE_Blog_post_April_2023.jpg`}
          className="banner-bg"
          alt=""
        />
        <MDBContainer>
          <MDBRow>
            <MDBCol size="12">
              <div className="Blog-BannerInfo">
                <Link to="/hik-china-blog">
                  <div className="Statusbar">
                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>06/14/23</h6>
                    </div>

                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>Analytical Report</h6>
                    </div>
                  </div>

                  <div className="Details">
                    <h2>
                      Sensitive Technology Trade - HIK Group (China) Analysis
                    </h2>
                    <p>
                      For the last several years, the U.S. government has been
                      developing policies and regulatory controls for military,
                      dual use and emerging...
                    </p>
                  </div>
                </Link>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </section>
    <section className="Blogs-Section">
      <img src="" alt="" className="back-bg" />
      <MDBContainer>
        <MDBRow>
          <h3 className="pad-bottom-15px">
            Explore more of our blog posts here.
          </h3>
          <MDBRow>
            <MDBCol size="12" xl="4" md="6" sm="12">
              <div className="Blog-Card">
                <Link to="/myanmar-teak-blog">
                  <div className="Blog-Describe-Title">
                    Sanctions Busting: Myanmar Teak
                  </div>
                  <div className="Blog-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/wood.jpg`}
                      alt=""
                    />
                  </div>

                  <div className="Statusbar">
                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>06/05/23</h6>
                    </div>

                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>Analytical Report</h6>
                    </div>
                  </div>

                  <div className="Blog-Describe-Details">
                    <p>
                      This month's BITE List entries focus on the international
                      movement of Myanmar teak wood which is highly prized in
                      yacht...
                    </p>
                  </div>
                </Link>
              </div>
            </MDBCol>
            <MDBCol size="12" xl="4" md="6" sm="12">
              <div className="Blog-Card">
                <Link to="/sanctions-evasion">
                  <div className="Blog-Describe-Title">
                    Sanctions Evasion Analysis in the Russian Defense Sector
                  </div>
                  <div className="Blog-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/UseBoxBottoms03.png`}
                      alt=""
                    />
                  </div>

                  <div className="Statusbar">
                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>3/24/23</h6>
                    </div>

                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>Analytical Report</h6>
                    </div>
                  </div>

                  <div className="Blog-Describe-Details">
                    <p>
                      In this article we provide further insight into our
                      analysis of ROSOBORONEKSPORT’, a Russia’s only
                      state-controlled intermediary in...
                    </p>
                  </div>
                </Link>
              </div>
            </MDBCol>
            <MDBCol size="12" xl="4" md="6" sm="12">
              <div className="Blog-Card">
                <a
                  href={OligarchsBlog}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="Blog-Describe-Title">
                    Using Advanced Analytics to Track Trade Flow of Sanctioned
                    Russian Oligarchs and Affiliated Businesses
                  </div>
                  <div className="Blog-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/UseBoxBottoms05.png`}
                      alt=""
                    />
                  </div>

                  <div className="Statusbar">
                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>8/01/22</h6>
                    </div>

                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>NU Borders News</h6>
                    </div>
                  </div>

                  <div className="Blog-Describe-Details">
                    <p>
                      NU Borders analyzed Russian manifests to determine
                      sanctioned Russian Oligarchs’ trade behavior. After
                      Russia's invasion of...
                    </p>
                  </div>
                </a>
              </div>
            </MDBCol>
            <MDBCol size="12" xl="4" md="6" sm="12">
              <div className="Blog-Card">
                <a
                  href={RussianEntityBlog}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="Blog-Describe-Title">
                    NU Borders Investigates Trade Transactions Affiliated with
                    Russian Entity, MILANDR AO.
                  </div>
                  <div className="Blog-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/oligarch.jpg`}
                      alt=""
                    />
                  </div>

                  <div className="Statusbar">
                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>09/14/22</h6>
                    </div>

                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>Newsletter</h6>
                    </div>
                  </div>

                  <div className="Blog-Describe-Details">
                    <p>
                      In partnership with the Department of State, ISN/CTR, NU
                      Borders produces newsletters containing analytical
                      assessments focused on identifying the movement of
                      potentially illicit dual-use goods and sanctioned entities
                      conducting business with Russia.
                    </p>
                  </div>
                </a>
              </div>
            </MDBCol>
            <MDBCol size="12" xl="4" md="6" sm="12">
              <div className="Blog-Card">
                <Link to="/steel-flange-case-study">
                  <div className="Blog-Describe-Title">
                    Looking At The Effects Of Steel ADD Orders Using Advanced
                    Analytics (Part 1)
                  </div>
                  <div className="Blog-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/steel-blog.jpeg`}
                      alt=""
                    />
                  </div>

                  <div className="Statusbar">
                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>07/29/22</h6>
                    </div>

                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>Case Studies</h6>
                    </div>
                  </div>

                  <div className="Blog-Describe-Details">
                    <p>
                      Using the BITE platform and almost 500 million trade
                      records (primarily manifest data), the team identified
                      multiple exports from various countries to see if they...
                    </p>
                  </div>
                </Link>
              </div>
            </MDBCol>
            <MDBCol size="12" xl="4" md="6" sm="12">
              <div className="Blog-Card">
                <a
                  href={Apr22Vol5PDF}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="Blog-Describe-Title">
                    Transactions between Chinese Multi-National ICBC STANDARD
                    BANK and Russian Banks
                  </div>
                  <div className="Blog-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/silver.png`}
                      alt=""
                    />
                  </div>

                  <div className="Statusbar">
                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>07/07/22</h6>
                    </div>

                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>Analytical Report</h6>
                    </div>
                  </div>

                  <div className="Blog-Describe-Details">
                    <p>
                      In this month’s edition, NU BORDERS analyzed Russian
                      import and export trade manifest data to uncover
                      transactions between the Chinese multi-national bank ICBC
                      STANDARD BANK and Russian banks.
                    </p>
                  </div>
                </a>
              </div>
            </MDBCol>

            <MDBCol size="12" xl="4" md="6" sm="12">
              <div className="Blog-Card">
                <a
                  href={Apr22Vol4PDF}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="Blog-Describe-Title">
                    Movement of Russian Gold
                  </div>
                  <div className="Blog-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/gold.png`}
                      alt=""
                    />
                  </div>

                  <div className="Statusbar">
                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>06/24/22</h6>
                    </div>

                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>Analytical Report</h6>
                    </div>
                  </div>

                  <div className="Blog-Describe-Details">
                    <p>
                      In this month’s edition, NU BORDERS analyzed Russian
                      Import and Export trade manifest data utilizing the
                      Harmonized Tariff Schedule to identify transactions
                      involving the movement of Gold.
                    </p>
                  </div>
                </a>
              </div>
            </MDBCol>

            <MDBCol size="12" xl="4" md="6" sm="12">
              <div className="Blog-Card">
                <a href={CoralPDF} target="_blank" rel="noopener noreferrer">
                  <div className="Blog-Describe-Title">
                    BITE Investigative Summary: Coral Exports
                  </div>
                  <div className="Blog-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/coral-exports.jpeg`}
                      alt=""
                    />
                  </div>

                  <div className="Statusbar">
                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>06/03/22</h6>
                    </div>

                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>Case Studies</h6>
                    </div>
                  </div>

                  <div className="Blog-Describe-Details">
                    <p>
                      Business Intelligence for Trade Enforcement (BITE)
                      Investigative Summary. Public report of coral exports.
                    </p>
                  </div>
                </a>
              </div>
            </MDBCol>

            <MDBCol size="12" xl="4" md="6" sm="12">
              <div className="Blog-Card">
                <a href={SharkFinPDF} target="_blank" rel="noopener noreferrer">
                  <div className="Blog-Describe-Title">
                    BITE Investigative Summary: Shark Fin Exports
                  </div>
                  <div className="Blog-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/sharkfin-exports.jpeg`}
                      alt=""
                    />
                  </div>

                  <div className="Statusbar">
                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>05/06/22</h6>
                    </div>

                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>Case Studies</h6>
                    </div>
                  </div>

                  <div className="Blog-Describe-Details">
                    <p>
                      Business Intelligence for Trade Enforcement (BITE)
                      Investigative Summary. Public report of shark fin exports.
                    </p>
                  </div>
                </a>
              </div>
            </MDBCol>

            <MDBCol size="12" xl="4" md="6" sm="12">
              <div className="Blog-Card">
                <a
                  href={WebinarInvitePDF}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="Blog-Describe-Title">
                    BITE Webinar Invite - Q1 2022
                  </div>
                  <div className="Blog-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/rhino.jpeg`}
                      alt=""
                    />
                  </div>

                  <div className="Statusbar">
                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>02/24/22</h6>
                    </div>

                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>Webinar Series</h6>
                    </div>
                  </div>

                  <div className="Blog-Describe-Details">
                    <p>
                      An invite to our BITE webinar series: Using advanced
                      analytics to stem illegal wildlife trade.
                    </p>
                  </div>
                </a>
              </div>
            </MDBCol>

            <MDBCol size="12" xl="4" md="6" sm="12">
              <div className="Blog-Card">
                <Link to="/2022-q1-newsletter">
                  <div className="Blog-Describe-Title">
                    Newsletter - Q1 2022
                  </div>
                  <div className="Blog-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/q1-newsletter.jpeg`}
                      alt=""
                    />
                  </div>

                  <div className="Statusbar">
                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>01/19/2022</h6>
                    </div>

                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>Newsletter</h6>
                    </div>
                  </div>

                  <div className="Blog-Describe-Details">
                    <p>
                      Welcome to NU Borders' First Quarter 2022 Business
                      Intelligence for Trade Enforcement (BITE) Newsletter!
                    </p>
                  </div>
                </Link>
              </div>
            </MDBCol>
            <MDBCol size="12" xl="4" md="6" sm="12">
              <div className="Blog-Card">
                <Link to="/zambesia-partnership">
                  <div className="Blog-Describe-Title">
                    NU Borders partners with Zambesia Conservation Alliance
                  </div>
                  <div className="Blog-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/UseBoxBottoms02.png`}
                      alt=""
                    />
                  </div>

                  <div className="Statusbar">
                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>12/02/2021</h6>
                    </div>

                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>Press Releases</h6>
                    </div>
                  </div>

                  <div className="Blog-Describe-Details">
                    <p>
                      NU Borders Partners with Zambesia Conservation Alliance to
                      use Advanced Analytics Solutions in the Fight Against
                      Illegal Wildlife Trade in Southern Africa
                    </p>
                  </div>
                </Link>
              </div>
            </MDBCol>
            <MDBCol size="12" xl="4" md="6" sm="12">
              <div className="Blog-Card">
                <Link to="/2021-q4-newsletter">
                  <div className="Blog-Describe-Title">
                    Newsletter - Q4 2021
                  </div>
                  <div className="Blog-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/q4-newsletter.jpeg`}
                      alt=""
                    />
                  </div>

                  <div className="Statusbar">
                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>11/1/2021</h6>
                    </div>

                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>Newsletter</h6>
                    </div>
                  </div>

                  <div className="Blog-Describe-Details">
                    <p>
                      Welcome to our Fourth Quarter NU Borders' Business
                      Intelligence for Trade Enforcement (BITE) Newsletter!
                    </p>
                  </div>
                </Link>
              </div>
            </MDBCol>

            <MDBCol size="12" xl="4" md="6" sm="12">
              <div className="Blog-Card">
                <Link to="/2021-q3-newsletter">
                  <div className="Blog-Describe-Title">
                    Newsletter - Q3 2021
                  </div>
                  <div className="Blog-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/q3-newsletter.jpeg`}
                      alt=""
                    />
                  </div>

                  <div className="Statusbar">
                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>08/25/2021</h6>
                    </div>

                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>Newsletter</h6>
                    </div>
                  </div>

                  <div className="Blog-Describe-Details">
                    <p>
                      The Business Intelligence for Trade Enforcement (BITE)
                      platform provides regulatory, compliance, and enforcement
                      teams with a suite of next-generation tools to automate
                      the identification of targets, gain access to large trade
                      datasets, and manage their workflow.
                    </p>
                  </div>
                </Link>
              </div>
            </MDBCol>
            <MDBCol size="12" xl="4" md="6" sm="12">
              <div className="Blog-Card">
                <Link to="/pdc-press-release">
                  <div className="Blog-Describe-Title">
                    NU Borders' BITE Platform uses Advanced Analytics to Support
                    Anti-Poaching in Southern Africa
                  </div>
                  <div className="Blog-img">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/painted-dogs.jpeg`}
                      alt=""
                    />
                  </div>
                  <div className="Statusbar">
                    <div className="Timing">
                      <img alt="" />
                      <h6>08/19/2021</h6>
                    </div>

                    <div className="Timing">
                      <img src="" alt="" />
                      <h6>Analytics</h6>
                    </div>
                  </div>
                </Link>

                <div className="Blog-Describe-Details">
                  <Link to="/pdc-press-release">
                    <p>
                      NU Borders has partnered with the Painted Dog Conservation
                      (PDC), a non-profit organization located in Hwange
                      National Park, Zimbabwe to provide PDC with access to the
                      Business Intelligence for Trade Enforcement (BITE)
                      platform – an analytical tool suite to help analyze and
                      track wildlife, look at the movement patterns and trends
                      of the animals and gain better insight into how PDC
                      resources can be optimized to support anti-poaching
                      efforts.
                    </p>
                  </Link>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBRow>
      </MDBContainer>
    </section>
  </React.Fragment>
);

export default Blog;
