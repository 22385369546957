export const TeamMemberData = [
  {
    title: "Thariq Kara",
    position: "Co-Founder, Chief Executive Officer",
    description:
      "Prior to founding NU Borders, Thariq was the Lead Data Architect at the Department of Homeland Security (DHS), Bureau of Immigration and Customs Enforcement (ICE).",
    peragraps01:
      "He has run the Data Architecture arm at ICE Office of the Chief Information Officer, with oversight over all IT systems in a $400 million portfolio, and helped set up ICE’s first Data Management Office. Thariq led an effort to restructure the data architecture and data management component for ICE’s major transactional systems and was the primary author of ICE’s Reference Data Architecture Guideline.",
    peragraps02:
      "Additionally, Thariq architected and managed ICE’s first Big Data storage and analytical system, that had the Hadoop File System (HDFS) at its core. The system supported ICE’s Homeland Security Investigations, National Security Investigations Division, and was integral to assisting HSI with WMD-related cases. He has held the position of Program Manager within ICE OCIO, for programs and projects that have covered data warehousing, search, analysis, visualization and data management.",
    peragraps03:
      "Thariq received a Master’s in Engineering Management from Duke University in 2008 and a Bachelor of Science in Mechanical Engineering from the Florida Institute of Technology in 2007. He has won the ICE Director’s Award as well the DHS S&T Under Secretary’s Award.",
  },
  {
    title: "Dr. Timothy Gildea",
    position: "Co-Founder, Chief Operating Officer",
    description:
      "Special Agent (Retired) Timothy N. Gildea worked for U.S. Immigration and Customs Enforcement (ICE), Homeland Security Investigations (HSI), and its predecessor agency U.S. Customs Service from 1983 to 2014.",
    peragraps01:
      "He has conducted numerous cross-border crime investigations and served on several forces including the Joint Terrorism Task Force and the Drug Enforcement Agency’s Transportation Task Force. From 2003 to 2009, Special Agent Gildea served at the Assistant ICE Attaché, Toronto Office. He has also served as a Special Agent and Counter-Proliferation Investigations Program Manager in Boston, MA and Washington, DC, respectively.",
    peragraps02:
      "For the last five years, Timothy has worked with the Department of Homeland Security, Science and Technology, the Export Enforcement Coordination Center (E2C2) and Northeastern University, in developing the Border Enforcement Analytics Program (BEAP), that established Big Data analytic capabilities and methodologies to search high-volume government and trade data sets for indicators of illicit proliferation and other border-related violations.",
    peragraps03:
      "Timothy received his Ph.D. in Law and Policy from Northeastern University, Boston, MA in 2003.",
  },
  {
    title: "Shawn Quinn",
    position: "Director of Human Resources & Contracts Administration",
    description:
      "Shawn Quinn is the Director of Human Resources and Contracts Administration for NU Borders. Having previously worked as the HR Director for a contract armed security company, she is very familiar with HR policies and procedures, benefits administration, and contract management. Shawn has a strong ability to streamline processes, maximize efficiency, and foster professional relationships within organizations. Shawn earned her B.S. in Criminal Justice from Bridgewater State University.",
  },
  {
    title: "Romaine Coston",
    position: "Software Engineer",
    description:
      "Romaine Coston is one of the Senior Software Engineer at NU Borders, and has over twenty years of experience in different technical disciplines. He first started out as a software engineer with Lockheed Martin for a little over three years after graduating from Xavier University in Louisiana. Romaine also had a love and passion (still does) for 3D computer graphics and animation which inspired him to move to San Francisco and earn his second degree in Media Arts and Animation. Right after graduation, Romaine landed a job at Industrial Light and Magic (ILM), one of the leading visual effects studios in the world. There he worked in the render support division as well as the layout department as ILM was gearing up to create its first animated feature, Rango. After ILM, Romaine had the wonderful opportunity to move to Vancouver and work for Method Studios. While there,  he worked on a number of movies as a layout artist, making sure to match 3D cameras to cameras that were used on set so the visual effects were added correctly. Currently, Romaine serves as one of our longest standing employees and he continues to work on mission-focused software projects for the company.",
  },
  {
    title: "Heather Pilkington",
    position: "UX/UI Designer",
    description:
      "Heather Pilkington earned a B.F.A in Graphic Design from Loyola Marymount University and holds a certification in UX Design from Cornell University. Before joining NU Borders, Heather spent seven years working for a diverse range of organizations, including small tech startups, private equity firms, healthcare corporations, and academic institutions. Passionate about design as a problem-solving tool, as our UX/UI designer, Heather helps improve the connection between the people we serve and the products they use.",
  },
  {
    title: "Ryan Tumel",
    position: "Full Stack Developer",
    description:
      "Ryan is currently a full stack developer working on the BITE 2.0 team and has worked on building up a backend for the application from the ground up including refactoring the frontend to create a seamless UI/UX experience using React. Ryan has onboarded new team members with the project and likes to assist in resolving code blockers we might experience through pair programming.",
  },
  {
    title: "Angela Reaves",
    position: "DevOps Engineer",
    description:
      "Angela Reaves has over 15 years experience designing and developing software solutions for enterprise clients. She earned her bachelor’s degree in Computer Science from Florida State University and recently completed a Cyber Defense Professional program from the University of Central Florida. Angela is focused on improving development, security, and operations as a Cyber/DevOps Engineer.",
  },
];
