import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MDBRow, MDBCol, MDBBtn, MDBContainer } from "mdbreact";

class SteelFlangePost extends Component {
  state = {
    modal: false,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    return (
      <React.Fragment>
        <section className="Blog-Banner BlogDetails-Banner">
          <img src="" alt="" />
          <MDBContainer className="Blog-Title">
            <MDBRow>
              <MDBCol size="12">
                <div className="BannerTitle">
                  <h1>
                    Looking At The Effects Of Steel Anti-Dumping Duty Orders
                    Using Advanced Analytics And Large Data Sets (Part 1)
                  </h1>
                </div>

                <div className="Statusbar">
                  <div className="Timing">
                    <img src="" alt="" />
                    <h6>07/29/2022</h6>
                  </div>

                  <div className="Timing">
                    <img src="" alt="" />
                    <h6>Case Studies</h6>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>

          <div className="Banner">
            <img
              src={`${process.env.PUBLIC_URL}/images/steel-blog.jpeg`}
              className="banner-bg"
              alt=""
            />
          </div>
        </section>

        <section className="Blogs-Description">
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12">
                <div className="Description">
                  <p>
                    NU Borders is a Washington DC and Boston-based tech company
                    that focuses on analyzing large amounts of trade data for
                    commercial and Federal Government customers to identify
                    trends, anomalies, investigative targets and more. Our
                    Business Intelligence for Trade Enforcement (BITE) platform
                    is used by multiple customers to analyze data, match names
                    on screening lists, perform deep-dive data science and
                    perform compliance-related checks on import/export
                    shipments.
                  </p>
                  <p>
                    This blog post is written in two parts, with Part 1 focusing
                    on U.S. trade data and a statistical analysis that reflects
                    trade trends and patterns after an anti-dumping duty order
                    for steel flanges was enacted in March of 2018, particularly
                    for trade from China and India. Part 2, to be published
                    soon, will focus on the specifics around potential
                    transhipment schemes that our team found in the data. This
                    is follow-up work that the BITE team initially performed in
                    2019, and is now updated with new U.S. import data.
                  </p>
                  <div>
                    <p>
                      The{" "}
                      <a
                        href="https://www.federalregister.gov/documents/2018/08/01/2018-16348/stainless-steel-flanges-from-the-peoples-republic-of-china-antidumping-duty-order"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        anti-dumping order
                      </a>{" "}
                      was designed due to certain countries using unfair trade
                      practices to support their industrial and commercial base.
                      These unfair trade practices include government subsidies
                      for energy, labor compensation, and/or tax breaks, which
                      artificially lower the costs for manufacturing
                      commodities. When these commodities are imported into the
                      United States at Less Than Fair Value (LTFV), U.S.
                      competitors suffer due to the dumping of the commodities
                      into the U.S. market and are often forced out of business.
                      In order to level the playing field, many governments –
                      including the U.S. – impose Antidumping and Countervailing
                      Duties (AD/CVD) orders which impose higher rates of duty
                      on violating countries and companies. Exporters from those
                      countries frequently transship commodities through
                      countries without AD/CVD orders and claim those
                      transshipment points as country of origin.
                    </p>
                    <p>
                      China has a history of flooding the market with products
                      and forcing out competition due to their ability for low
                      overhead. During 2018, the U.S. published multiple
                      sanctions packages against China due to the steel flange
                      product. Using the BITE platform and almost 500 million
                      trade records (primarily manifest data), the team
                      identified multiple exports from various countries to see
                      if they had any relation to Chinese companies after the
                      anti-dumping order was enacted.
                    </p>
                    <p>
                      The manifest data used in this analysis includes core data
                      elements for each transaction, such as the method of
                      transport, shipper, consignee (importer), country of
                      origin and receipt, brand, product description, harmonized
                      system (HS) code and shipment dollar value. HS codes are a
                      numerical system developed and managed by the World
                      Customs Organization and used to classify trade products
                      and goods on a global level. The HS codes are consistent
                      internationally up to the sixth digit.
                    </p>
                  </div>
                  <div>
                    <p>
                      The HS codes listed in the antidumping act were
                      7307.21.1000 and 7307.21.5000. These HS codes were queried
                      in the Chinese, Indonesian, Indian, Malaysian, and U.S.
                      manifest datasets. With guidance from NU Borders federal
                      border security and law enforcement subject matter
                      experts, the team of data scientists started to focus on
                      the nexus of trade between Vietnam, Indonesia, India and
                      Malaysia, and the connection to Chinese companies that
                      would use ports in these countries to circumvent the ADD
                      orders in the U.S. By looking into the manifest data, the
                      team hoped to identify a connection between companies that
                      appeared in U.S. data as well as other Asian countries'
                      data sets. The BITE-ingested U.S. import manifest data
                      consists of over seventy-six million records (or unique
                      trade transactions).
                    </p>
                  </div>
                  <p>
                    After filtering for HS code 730721 for all data in the
                    system, approximately twenty-three thousand records remain,
                    with the top country importers shown below in Figure 1:
                  </p>
                  <div>
                    <img
                      className="circle-graph"
                      src={`${process.env.PUBLIC_URL}/images/steel-flange2.jpg`}
                      alt=""
                    />
                    <p>Fig. 1 - Top Countries US Imports 730721</p>
                  </div>
                  <div>
                    <img
                      className="steel-img-long"
                      src={`${process.env.PUBLIC_URL}/images/steel-flange3.jpg`}
                      alt=""
                    />
                    <p>Fig 2. : Total shipments with HS code 730721</p>
                  </div>
                </div>
                <div>
                  <p>
                    Further filtering to show shipments prior to the ADD order
                    are shown below in Figure 3 (Notice Vietnam is low on the
                    list):
                  </p>
                </div>
                <div>
                  <img
                    className="steel-img-long"
                    src={`${process.env.PUBLIC_URL}/images/steel-flange4.jpg`}
                    alt=""
                  />
                  <p>Fig 3: Shipments prior to ADD order enacted in 2018</p>
                </div>
                <div>
                  <div>
                    <p>
                      In Figure 4 (below), that reflects top countries importing
                      steel flanges into the U.S. after the AD/CVD order, notice
                      that Vietnam has increased its exports into the U.S. as
                      well as Korea, Romania and Singapore
                    </p>
                  </div>
                  <div>
                    <img
                      className="steel-img-long"
                      src={`${process.env.PUBLIC_URL}/images/steel-flange5.jpg`}
                      alt=""
                    />
                    <p>
                      Fig. 4: Shipments by country into the U.S. after the ADD
                      order was enacted.
                    </p>
                  </div>
                  <div>
                    <p>
                      Part 1 of our analysis provides us with enough information
                      to conclude that further investigation of the data is
                      needed. We now know that there was a spike in steel flange
                      shipments from certain countries, after the enactment of
                      the anti-dumping duty order, with a decrease in imports
                      from China and India. We will begin to explore these
                      spikes in more detail, including going down to the
                      shipment / entity level to identify trends and reasons for
                      the spikes and troughs.
                    </p>
                    <p>
                      This analysis was led by Kathryn Williams, a DC-based data
                      scientist with NU Borders. For further information on any
                      of the content, please reach out to{" "}
                      <a href="mailto: bite@nuborders.com">
                        bite@nuborders.com
                      </a>
                    </p>
                  </div>
                  <Link to="/blog">
                    <MDBBtn className="ThemeBtn">Back to blogs</MDBBtn>
                  </Link>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </React.Fragment>
    );
  }
}

export default SteelFlangePost;
