import React, { useEffect, useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";
import { UseCasesData as data } from "./UseCasesData";

const UseCases = () => {
  const [modal, setModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const toggle = () => {
    setModal(!modal);
  };

  const openModal = (id) => {
    setActiveIndex(id);
    toggle();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ModalPage = () => {
    const record = data[activeIndex];
    return (
      <MDBModal isOpen={modal} toggle={toggle} centered size="lg" height="100%">
        <MDBModalHeader toggle={toggle}>{record.title}</MDBModalHeader>
        <MDBModalBody>
          <div className="ModalDetails">
            <iframe
              style={{ height: 800, width: "100%" }}
              title={record.title}
              src={record.pdfSrc}
            />
          </div>
        </MDBModalBody>
      </MDBModal>
    );
  };

  return (
    <React.Fragment>
      <ModalPage />
      <section className="UseCasesBanner mt-sm-70">
        <img
          src={`${process.env.PUBLIC_URL}/images/UseCasesBanner.png`}
          alt=""
        />
      </section>
      <section className="postion_top">
        <MDBContainer>
          <MDBRow>
            <MDBCol size="12" md="6" sm="12">
              <div className="ServicesInnerTop usercasesbox">
                <h1>Use Cases</h1>
                <p>
                  The BITE platform includes tools and data that allow users to
                  performance investigative analytics across multiple domains.
                  Example use cases below provide insight into some of these
                  uses cases, and the customers we support
                </p>
              </div>
              <div className="BacksDots">
                <img
                  src={`${process.env.PUBLIC_URL}/images/BacksDots.png`}
                  alt=""
                />
              </div>
            </MDBCol>

            <MDBCol size="6" md="6" sm="6" className="d-none d-md-block">
              <div className="UseRings text-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/Ellipse.png`}
                  alt=""
                />
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <section>
        <MDBContainer>
          <MDBRow>
            {data.map((record, index) => (
              <MDBCol size="12" md="3" sm="12" key={record.title}>
                <div
                  className="UesCasesBottomBox globalPointer"
                  onClick={() => openModal(index)}
                >
                  <div className="BottomImg">
                    <img src={record.imgSrc} alt={record.title} />
                  </div>
                  <h4>{record.title}</h4>
                </div>
              </MDBCol>
            ))}
          </MDBRow>
        </MDBContainer>
      </section>
    </React.Fragment>
  );
};

export default UseCases;
