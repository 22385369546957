import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MDBRow, MDBCol, MDBBtn, MDBContainer } from "mdbreact";

class MyanmarTeakBlog extends Component {
  state = {
    modal: false,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    return (
      <React.Fragment>
        <section className="Blog-Banner BlogDetails-Banner">
          <MDBContainer className="Blog-Title">
            <MDBRow>
              <MDBCol size="12">
                <div className="BannerTitle">
                  <h1>Sanctions Busting: Myanmar Teak</h1>
                </div>

                <div className="Statusbar">
                  <div className="Timing">
                    <img src="" alt="" />
                    <h6>06/05/2023</h6>
                  </div>

                  <div className="Timing">
                    <img src="" alt="" />
                    <h6>NU Borders News</h6>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <div className="Banner">
            <img
              src={`${process.env.PUBLIC_URL}/images/wood.jpg`}
              className="banner-bg"
              alt=""
            />
          </div>
        </section>
        <section className="Blogs-Description">
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12">
                <div className="Description">
                  <div>
                    <p>
                      <u>
                        <strong>This Month's Focus</strong>
                      </u>
                    </p>
                    <p>
                      NU Borders, LLC operates the Border Intelligence for Trade
                      Enforcement (BITE) platform which aggregates large-scale
                      international manifest data for advanced analytics. Our
                      enforcement SMEs continuously query BITE to identify
                      suspicious transactions in the trade data, particularly
                      for 2nd and 3rd tier entities that may not appear in court
                      documents or Watch Lists but are involved in the illicit
                      movement of controlled or protected commodities. These
                      entities, not captured on any other Watch lists, play
                      critical roles in sanctions evasion and their identifiers
                      significantly enhance risk management programs. The
                      intermediary/transshipment companies are provided via our
                      BITE List which is available at a very low subscription
                      fee (https://nuborders.com/contact).
                    </p>
                    <p>
                      This month's BITE List entries focus on the international
                      movement of Myanmar teak wood which is highly prized in
                      yacht manufacturing, high-end furniture and other luxury
                      products that require superior water and pest-resistant
                      wood. In April 2021, the U.S. Treasury Department*
                      designated Burmese state-owned enterprise Myanmar Timber
                      Enterprise (MTE) in response to the military junta's coup
                      in February of that year. The European Union implemented
                      similar sanctions in June 2021.** MTE is the state-owned
                      enterprise responsible for Myanmar's timber and teak trade
                      (conflict wood).
                    </p>
                    <p>
                      Despite the sanctions, large amounts of teak shipments are
                      exported from Myanmar to other countries, including the
                      U.S.,*** which help finance the military regime's human
                      rights abuses. In addition to the U.S., India - which has
                      no ban on Myanmar teak - imports and processes significant
                      quantities. BITE queries also reveal several companies in
                      Vietnam receiving Myanmar teak after the U.S. and EU
                      implemented sanctions.
                    </p>
                    <p>
                      Although these shipments may not violate Vietnamese law,
                      responsible companies should be aware of their connections
                      to MTE and exercise greater due diligence when trading
                      with the Vietnam companies listed below. NU Borders will
                      continue to research these 2nd and 3rd tier intermediaries
                      and document the entities on our BITE List to provide risk
                      management and compliance officials greater insight into
                      their trade activities.
                    </p>
                    <p>
                      <ul>
                        <li>
                          *
                          <a
                            href="https://home.treasury.gov/news/press-releases/jy0138"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://home.treasury.gov/news/press-releases/jy0138
                          </a>
                        </li>
                        <li>
                          **
                          <a
                            href="https://assets.gov.ie/153115/9ef90328-d535-4842-9680-dd96987ce650.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://assets.gov.ie/153115/9ef90328-d535-4842-9680-dd96987ce650.pdf
                          </a>
                        </li>
                        <li>
                          ***
                          <a
                            href="https://www.justiceformyanmar.org/stories/us-companies-imported-nearly-1-600-tonnes-of-myanmar-teak-circumventing-sanctions"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://www.justiceformyanmar.org/stories/us-companies-imported-nearly-1-600-tonnes-of-myanmar-teak-circumventing-sanctions
                          </a>
                        </li>
                      </ul>
                    </p>
                    <img
                      style={{ width: "100%" }}
                      src={`${process.env.PUBLIC_URL}/images/2.jpg`}
                      alt="bite list update May 2023"
                    />
                    <img
                      style={{ width: "100%" }}
                      src={`${process.env.PUBLIC_URL}/images/myanmarteakprofile.png`}
                      alt="myanmar teak profile"
                    />
                    <div style={{ marginTop: "20px" }}>
                      <p>
                        <u>
                          <strong>Accessing the BITE List</strong>
                        </u>
                      </p>
                      <p>
                        BITE and the BITE List are priced competitively so all
                        users can apply efficient, seamless risk-management
                        solutions at negligible costs.
                      </p>
                      <p>
                        To access the BITE List register&nbsp;
                        <a
                          href="https://app.bitedata.io/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          here&nbsp;
                        </a>
                        or email us at bite@nuborders.com
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <Link to="/blog">
                    <MDBBtn className="ThemeBtn">Back to blogs</MDBBtn>
                  </Link>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </React.Fragment>
    );
  }
}

export default MyanmarTeakBlog;
