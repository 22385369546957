import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import { BtnArrowSmall } from "../Icons";
import { TeamMemberData } from "./TeamMemberData";

class Team extends Component {
  state = {
    modal: false,
    activeIndex: 0,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  openModal = (i) => {
    this.setState({ activeIndex: i });
    this.toggle();
  };

  render() {
    return (
      <React.Fragment>
        <MDBModal
          isOpen={this.state.modal}
          toggle={this.toggle}
          centered
          size="lg"
        >
          <MDBModalHeader toggle={this.toggle}>Meet Our Team</MDBModalHeader>

          <MDBModalBody>
            <div className="ModalDetails">
              <h3>{TeamMemberData[this.state.activeIndex].title}</h3>
              <h4>{TeamMemberData[this.state.activeIndex].position}</h4>
              <p>{TeamMemberData[this.state.activeIndex].description}</p>
              <p>{TeamMemberData[this.state.activeIndex].peragraps01}</p>
              <p>{TeamMemberData[this.state.activeIndex].peragraps02}</p>
              <p>{TeamMemberData[this.state.activeIndex].peragraps03}</p>
              <p>{TeamMemberData[this.state.activeIndex].peragraps04}</p>
            </div>
          </MDBModalBody>
        </MDBModal>

        <section className="pt-md-5 pt-4 pb-0 mt-sm-70">
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12">
                <div className="PageSubTitles text-center">
                  <h3>Meet Our Team</h3>
                  <p>
                    NU Borders prides itself in building a diverse, skilled and
                    competent workforce. We strongly believe in balance, and{" "}
                    <br></br>
                    ensuring that employees have the right tools they need to do
                    their jobs successfully.
                  </p>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <section className="pb-md-5 pb-4">
          <MDBContainer>
            <MDBRow className="justify-content-center">
              <MDBCol size="12" lg="3" md="6" sm="12">
                <div className="MembersInner">
                  <div className="TeamHeadshot">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/teammembers.jpg`}
                      alt=""
                    />
                  </div>
                  <div className="MetaDetails">
                    <h3>Thariq Kara</h3>
                    <h4>Chief Executive Officer</h4>
                    <div className="TeamReadMore">
                      <MDBBtn
                        onClick={() => {
                          this.openModal(0);
                        }}
                        className="ThemeBtn SmallBtn"
                      >
                        Read More <BtnArrowSmall />
                      </MDBBtn>
                    </div>
                  </div>
                </div>
              </MDBCol>

              <MDBCol size="12" lg="3" md="6" sm="12">
                <div className="MembersInner">
                  <div className="TeamHeadshot">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/Member02.jpg`}
                      alt=""
                    />
                  </div>

                  <div className="MetaDetails">
                    <h3>Timothy Gildea</h3>
                    <h4>Chief Operating Officer</h4>
                    <div className="TeamReadMore">
                      <MDBBtn
                        onClick={() => {
                          this.openModal(1);
                        }}
                        className="ThemeBtn SmallBtn"
                      >
                        Read More <BtnArrowSmall />
                      </MDBBtn>
                    </div>
                  </div>
                </div>
              </MDBCol>

              <MDBCol size="12" lg="3" md="6" sm="12">
                <div className="MembersInner">
                  <div className="TeamHeadshot">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/Member03.jpg`}
                      alt=""
                    />
                  </div>

                  <div className="MetaDetails">
                    <h3>Shawn Quinn</h3>
                    <h4>Director of Human Resources</h4>
                    <div className="TeamReadMore">
                      <MDBBtn
                        onClick={() => {
                          this.openModal(2);
                        }}
                        className="ThemeBtn SmallBtn"
                      >
                        Read More <BtnArrowSmall />
                      </MDBBtn>
                    </div>
                  </div>
                </div>
              </MDBCol>

              <MDBCol size="12" lg="3" md="6" sm="12">
                <div className="MembersInner">
                  <div className="TeamHeadshot">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/Member11.jpg`}
                      alt=""
                    />
                  </div>

                  <div className="MetaDetails">
                    <h3>Romaine Coston</h3>
                    <h4>Software Engineer</h4>
                    <div className="TeamReadMore">
                      <MDBBtn
                        onClick={() => {
                          this.openModal(3);
                        }}
                        className="ThemeBtn SmallBtn"
                      >
                        Read More <BtnArrowSmall />
                      </MDBBtn>
                    </div>
                  </div>
                </div>
              </MDBCol>

              <MDBCol size="12" lg="3" md="6" sm="12">
                <div className="MembersInner">
                  <div className="TeamHeadshot">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/Member18.jpg`}
                      alt=""
                    />
                  </div>

                  <div className="MetaDetails">
                    <h3>Heather Pilkington</h3>
                    <h4>UX/UI Engineer</h4>
                    <div className="TeamReadMore">
                      <MDBBtn
                        onClick={() => {
                          this.openModal(4);
                        }}
                        className="ThemeBtn SmallBtn"
                      >
                        Read More <BtnArrowSmall />
                      </MDBBtn>
                    </div>
                  </div>
                </div>
              </MDBCol>

              <MDBCol size="12" lg="3" md="6" sm="12">
                <div className="MembersInner">
                  <div className="TeamHeadshot">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/Member21.jpg`}
                      alt=""
                    />
                  </div>

                  <div className="MetaDetails">
                    <h3>Ryan Tumel</h3>
                    <h4>Full Stack Developer</h4>
                    <div className="TeamReadMore">
                      <MDBBtn
                        onClick={() => {
                          this.openModal(5);
                        }}
                        className="ThemeBtn SmallBtn"
                      >
                        Read More <BtnArrowSmall />
                      </MDBBtn>
                    </div>
                  </div>
                </div>
              </MDBCol>

              <MDBCol size="12" lg="3" md="6" sm="12">
                <div className="MembersInner">
                  <div className="TeamHeadshot">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/Member24.jpg`}
                      alt=""
                    />
                  </div>

                  <div className="MetaDetails">
                    <h3>Angela Reaves</h3>
                    <h4>DevOps Engineer</h4>
                    <div className="TeamReadMore">
                      <MDBBtn
                        onClick={() => {
                          this.openModal(6);
                        }}
                        className="ThemeBtn SmallBtn"
                      >
                        Read More <BtnArrowSmall />
                      </MDBBtn>
                    </div>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </React.Fragment>
    );
  }
}

export default Team;
