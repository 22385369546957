import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
} from "mdbreact";

class Q12022NewsletterBlog extends Component {
  state = {
    modal: false,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    return (
      <React.Fragment>
        <section className="Blog-Banner BlogDetails-Banner">
          <img src="" alt="" />
          <MDBContainer className="Blog-Title">
            <MDBRow>
              <MDBCol size="12">
                <div className="BannerTitle">
                  <h1>
                    Welcome to NU Borders' First Quarter 2022 Business
                    Intelligence for Trade Enforcement (BITE) Newsletter!
                  </h1>
                </div>

                <div className="Statusbar">
                  <div className="Timing">
                    <img src="" alt="" />
                    <h6>01/19/2022</h6>
                  </div>

                  <div className="Timing">
                    <img src="" alt="" />
                    <h6>Newsletter</h6>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>

          <div className="Banner">
            <img
              src={`${process.env.PUBLIC_URL}/images/pexels-marc-mueller-380769.png`}
              className="banner-bg"
              alt=""
            />
          </div>
        </section>

        <section className="Blogs-Description">
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12">
                <div className="Description">
                  <p>Hello and welcome to 2022!</p>
                  <p>
                    The Quarterly BITE Newsletter provides an overview of some
                    of the work the BITE team has completed in the past 3
                    months.
                  </p>
                  <p>
                    BITE is an advanced analytical platform that allows trade
                    enforcement and compliance teams to quickly ingest and
                    analyze large amounts of trade data. The platform contains
                    millions of trade records from across the world as well as a
                    case management tool and the ability to identify suspicious
                    parties within the supply chain.
                  </p>
                  <div>
                    <h3>What we've been up to:</h3>
                    <div>
                      <ol>
                        <li>
                          The State Department's Bureau of International
                          Security and Non-Proliferation (ISN) is using BITE on
                          four multi-year grants! In the month of December, we
                          provided training to a key national security group in
                          the Ukraine as part of our support for Counter Threat
                          Reduction efforts. In addition, our team is producing
                          a monthly Counter Threat newsletter on behalf of the
                          State Department to provide partner countries in the
                          Black Sea region with the latest findings regarding
                          sensitive technology proliferation in the region.
                        </li>
                        <li>
                          BITE continues to be used at the Department of
                          Justice, within the Intelligence Community and the
                          Department of Defense - we truly enjoy supporting
                          these customers and doing our part to ensure national
                          security.
                        </li>
                        <li>
                          We have great partners! The Institute for Science and
                          International Security, Page 4 Group, and Siracusa
                          International Institute provide key inputs on our work
                          with the Department of State across multiple
                          geographical areas including South America, Baltics,
                          Mediterranean, South East Asia and the Balkans.
                        </li>
                        <li>
                          BITE 2.0 is wrapped up and ready to go! We’ve
                          completed code on our new Software as a Service
                          offering that will include more tools for trade
                          compliance and enforcement professionals - we’re
                          focused on making the lives of import/export and
                          support chain compliance personnel easier!
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div>
                    <h3>What we've learned: </h3>
                    <ol>
                      <li>
                        Our Big Data ingest pipeline has grown to be more robust
                        and accurate - we’ve significantly reduced the time it
                        takes to get very large static datasets into the
                        platform, with all necessary cleansing and enrichments.
                        We need to document all the add-ons to the ELT process
                        and ensure all engineers are aware of any changes made
                        to the code in Dev.
                      </li>
                      <li>
                        Data enrichments are our strong point - all our data is
                        overlaid with multiple international screening and
                        watchlists so that analysts can easily pinpoint
                        transactions and entities of interest within the supply
                        chain.
                      </li>
                      <li>
                        Our production definition needs work - there is a lot
                        going on with BITE and we need to make sure our
                        customers understand the full power of the platform.
                      </li>
                    </ol>
                  </div>
                  <div>
                    <h3>What we're doing next: </h3>
                    <ol>
                      <li>
                        Building on our work within the International Wildlife
                        Trafficking (IWT) domain, we will plan to hold a webinar
                        at the end of February in support of IWT, with our
                        partners at ZCA, PDC and FCS. Our team will demo BITE
                        use cases specific to IWT and identify how data
                        analytics can support this important mission space.
                        We’ll hear from our partners about the critical
                        initiatives they are undertaking.
                      </li>
                      <li>
                        We’re going to start blogging soon! We’re finding a lot
                        of interesting things in our trade data across multiple
                        domains that we’d like to share with you - look out for
                        the blog coming soon to our website.
                      </li>
                      <li>
                        BITE 2.0 will officially launch this quarter - we’re
                        rigorously testing and preparing our DevOps pipeline to
                        support the new product. We’re planning to pilot BITE
                        2.0 with seasoned compliance specialists and look
                        forward to helping create efficiencies in the day-to-day
                        workflow.
                      </li>
                    </ol>
                  </div>
                </div>
              </MDBCol>
              <br />
              <MDBCol size="12" className="mt-3">
                <div>
                  <Link to="/blog">
                    <MDBBtn className="ThemeBtn">Back to blogs</MDBBtn>
                  </Link>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <MDBModal
          className="MemberProfile BlogShare"
          backdropClassName="ProfilePop"
          disableBackdrop
          isOpen={this.state.modal}
          toggle={this.toggle}
          centered
        >
          <MDBBtn onClick={this.toggle} className="CancelBtn">
            <img src={`${process.env.PUBLIC_URL}/images/cancel.svg`} alt="" />
          </MDBBtn>
          <MDBModalBody>
            <div className="BlogShare-ModelBody">
              <h4 className="Title">Share</h4>
              <div className="UR-Info">
                <div className="URL">
                  <p>bitly.xyz.13haerrvba…</p>
                </div>
                <MDBBtn className="CopyBtn">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/copy-btn.svg`}
                    alt=""
                  />
                </MDBBtn>
              </div>

              <div className="Share-Social">
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social1.svg`}
                    alt=""
                  />
                </MDBBtn>
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social2.svg`}
                    alt=""
                  />
                </MDBBtn>
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social3.svg`}
                    alt=""
                  />
                </MDBBtn>
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social4.svg`}
                    alt=""
                  />
                </MDBBtn>
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social5.svg`}
                    alt=""
                  />
                </MDBBtn>
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social6.svg`}
                    alt=""
                  />
                </MDBBtn>
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social7.svg`}
                    alt=""
                  />
                </MDBBtn>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-5X4Q69C"
            height="0"
            width="0"
            title="seo"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
      </React.Fragment>
    );
  }
}

export default Q12022NewsletterBlog;
