import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
} from "mdbreact";

class Q32021NewsletterBlog extends Component {
  state = {
    modal: false,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    return (
      <React.Fragment>
        <section className="Blog-Banner BlogDetails-Banner">
          <MDBContainer className="Blog-Title">
            <MDBRow>
              <MDBCol size="12">
                <div className="BannerTitle">
                  <h1>
                    Welcome to our Third Quarter NU Borders' Business
                    Intelligence for Trade Enforcement (BITE) Newsletter!
                  </h1>
                </div>

                <div className="Statusbar">
                  <div className="Timing">
                    <img src="" alt="" />
                    <h6>8/25/21</h6>
                  </div>

                  <div className="Timing">
                    <img src="" alt="" />
                    <h6>Newsletter</h6>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>

          <div className="Banner">
            <img
              src={`${process.env.PUBLIC_URL}/images/pexels-marc-mueller-380769.png`}
              className="banner-bg"
              alt=""
            />
          </div>
        </section>

        <section className="Blogs-Description">
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12">
                <div className="Description">
                  <p>
                    The Business Intelligence for Trade Enforcement (BITE)
                    platform provides regulatory, compliance, and enforcement
                    teams with a suite of next-generation tools to automate the
                    identification of targets, gain access to large trade
                    datasets, and manage their workflow.
                  </p>
                  <div>
                    <h3>We’ve Been Busy: </h3>
                    <p>
                      In August 2021, NU Borders was ranked as one of the
                      fastest-growing private companies in the U.S. by Inc.
                      5000.
                    </p>
                  </div>
                  <div>
                    <h3>Data:</h3>
                    <p>The BITE team has:</p>
                    <ol>
                      <li>Updated our screening lists </li>
                      <li>Refined our sensitive technology filters </li>
                      <li>
                        Built out additional dashboards and visualized data that
                        drill down on illicit trade activity{" "}
                      </li>
                      <li>
                        Ingested and cleaned additional countries’ manifest data
                      </li>
                    </ol>
                  </div>
                  <div>
                    <h3>Webinar Alert: </h3>
                    <p>
                      Our September BITE Webinar will be jointly hosted by the
                      Institute for Science and International Security.
                    </p>
                    <p>
                      This quarter we will focus on nuclear procurement trends
                      in Southeast Asia. Due to the sensitivity of the material,
                      we keep the webinars to government personnel only.
                    </p>
                    <p>
                      After each Webinar, we provide the BITE Report which
                      includes additional details around the Webinar topic.
                    </p>
                  </div>
                  <div>
                    <h3>Training:</h3>
                    <p>
                      NU Borders’ training team conducted separate training
                      sessions on BITE analytics for customs agencies in
                      Georgia, Indonesia, and Lithuania. We are building out an
                      on-premise customized BITE platform solution and training
                      on the use of data analytics to disrupt illicit regional
                      supply chains for government representatives in the
                      country of Georgia.{" "}
                    </p>
                  </div>
                  <div>
                    <h3>Customer focus:</h3>
                    <p>
                      NU Borders has partnered with the Painted Dog Conservation
                      (PDC), a non-profit organization located in Hwange
                      National Park, Zimbabwe to provide PDC with access to the
                      BITE platform to help analyze and track wildlife, look at
                      movement patterns and trends of the animals and gain
                      better insight into how PDC resources can be optimized to
                      support anti-poaching efforts. Our BITE team delivered
                      hands-on training of the BITE platform to PDC rangers, in
                      July 2021.{" "}
                    </p>
                    <p>
                      We continue to demo to and work with multiple
                      organizations in the government and private sector, with
                      the aim of helping our clients gain a better understanding
                      of their trade enforcement and compliance capabilities.
                    </p>
                  </div>
                </div>
              </MDBCol>
              <MDBCol size="12" className="mt-3">
                <div>
                  <Link to="/blog">
                    <MDBBtn className="ThemeBtn">Back to blogs</MDBBtn>
                  </Link>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <MDBModal
          className="MemberProfile BlogShare"
          backdropClassName="ProfilePop"
          disableBackdrop
          isOpen={this.state.modal}
          toggle={this.toggle}
          centered
        >
          <MDBBtn onClick={this.toggle} className="CancelBtn">
            <img src={`${process.env.PUBLIC_URL}/images/cancel.svg`} alt="" />
          </MDBBtn>
          <MDBModalBody>
            <div className="BlogShare-ModelBody">
              <h4 className="Title">Share</h4>
              <div className="UR-Info">
                <div className="URL">
                  <p>bitly.xyz.13haerrvba…</p>
                </div>
                <MDBBtn className="CopyBtn">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/copy-btn.svg`}
                    alt=""
                  />
                </MDBBtn>
              </div>

              <div className="Share-Social">
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social1.svg`}
                    alt=""
                  />
                </MDBBtn>
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social2.svg`}
                    alt=""
                  />
                </MDBBtn>
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social3.svg`}
                    alt=""
                  />
                </MDBBtn>
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social4.svg`}
                    alt=""
                  />
                </MDBBtn>
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social5.svg`}
                    alt=""
                  />
                </MDBBtn>
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social6.svg`}
                    alt=""
                  />
                </MDBBtn>
                <MDBBtn>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Share-Social7.svg`}
                    alt=""
                  />
                </MDBBtn>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-5X4Q69C"
            height="0"
            width="0"
            title="seo"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
      </React.Fragment>
    );
  }
}

export default Q32021NewsletterBlog;
