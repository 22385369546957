import React, { useEffect, useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
} from "mdbreact";
import {
  SystemDesign,
  SystemIntegration,
  SoftwareDevelopment,
  SoftwareDevelopmentICon,
  Datascience,
  DeepLearning,
  DevOps,
  Architecture,
  ApplicationDevelopment,
  DataManagement,
  Cloudservices,
} from "./Icons";
import { useLocation } from "react-router-dom";
// import { offset } from "highcharts";
const Service = () => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState("1");

  const toggle = (tab) => (e) => {
    if (activeItem !== tab) {
      setActiveItem(tab);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //console.log(location)
    if (location.hash === "#sysIntegration") {
      setActiveItem("2");
    }
    if (location.hash === "#sysDesign") {
      setActiveItem("1");
    }
    if (location.hash === "#softwareDevelopment") {
      setActiveItem("3");
    }
  }, [location]);

  const addClass = () => {
    document.getElementById("ThemeTabs").classList.toggle("TabItem-3");
  };

  return (
    <React.Fragment>
      <section className="py-md-5 pt-0  pb-2 mt-sm-70">
        <MDBContainer>
          <MDBRow className="align-items-center Col-reverse-sm">
            <MDBCol size="12" md="6" sm="12">
              <div className="ServicesInnerTop">
                <h1>
                  Custom IT Services and <br></br> Solutions
                </h1>
                <p>
                  The NU Borders team consists of highly skilled IT
                  professionals who have designed and maintained high-volume,
                  complex data systems for the U.S. Government and private
                  industry. We work closely with our clients to identify and
                  assess their organizational requirements, challenges, and
                  mission in order to design a custom solution that addresses
                  their specific data and IT issues.
                </p>
              </div>
            </MDBCol>

            <MDBCol size="12" md="6" sm="12">
              <div className="BnnerWidth">
                <img
                  src={`${process.env.PUBLIC_URL}/images/Dots-img.svg`}
                  alt=""
                  className="Dots-effect"
                />
                <img
                  src={`${process.env.PUBLIC_URL}/images/services-solution.jpg`}
                  alt=""
                />
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <section className="py-md-5 py-4 ServiceTabs bg-F5F5F5">
        <MDBContainer>
          <MDBRow className="align-items-center">
            <MDBCol size="12" md="12" sm="12">
              <MDBNav className="nav-tabs CustomTabs" id="ThemeTabs">
                <MDBNavItem
                  active={activeItem === "1"}
                  onClick={toggle("1")}
                  role="tab"
                  id="sysDesign"
                >
                  <MDBNavLink link to="#" onClick={addClass}>
                    <div className="Tab-Icon">
                      <SystemDesign />
                    </div>
                    System Design
                  </MDBNavLink>
                </MDBNavItem>

                <MDBNavItem
                  active={activeItem === "2"}
                  onClick={toggle("2")}
                  role="tab"
                  id="sysIntegration"
                >
                  <MDBNavLink link to="#" onClick={addClass}>
                    <div className="Tab-Icon">
                      <SystemIntegration />
                    </div>
                    System Integration
                  </MDBNavLink>
                </MDBNavItem>

                <MDBNavItem
                  active={activeItem === "3"}
                  onClick={toggle("3")}
                  role="tab"
                  id="softwareDevelopment"
                >
                  <MDBNavLink link to="#" onClick={addClass}>
                    <div className="Tab-Icon">
                      <SoftwareDevelopment />
                    </div>
                    Software Development
                  </MDBNavLink>
                </MDBNavItem>
              </MDBNav>

              <MDBTabContent
                activeItem={activeItem}
                className="TabsContent"
                id="about"
              >
                <MDBTabPane tabId="1" role="tabpanel">
                  <MDBRow>
                    <MDBCol size="12" md="4" sm="12">
                      <div className="Bussiness_Banner">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/toggle-1-300x238.png`}
                          alt=""
                        />
                      </div>
                    </MDBCol>
                    <MDBCol size="12" md="8" sm="12">
                      <div className="Content">
                        <h4>
                          NU Borders designs and builds custom, enterprise-wide
                          IT architecture and provides comprehensive data
                          solutions to help clients manage their most pressing
                          data needs. We do this by:
                        </h4>
                        <div className="ListCont">
                          <ul>
                            <li>
                              Collecting and analyzing the necessary business
                              requirements.
                            </li>
                            <li>
                              Aligning business functions to IT operations.
                            </li>
                            <li>
                              Creating a custom blueprint for the IT system and
                              the overall organizational data architecture.
                            </li>
                          </ul>
                        </div>
                        <p>
                          We also provide data-management services to align data
                          storage with business functions while ensuring quality
                          data governance and legal compliance.
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBTabPane>
                <MDBTabPane tabId="2" role="tabpanel">
                  <MDBRow>
                    <MDBCol size="12" md="4" sm="12">
                      <div className="Bussiness_Banner">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/toggle-2.png`}
                          alt=""
                        />
                      </div>
                    </MDBCol>
                    <MDBCol size="12" md="8" sm="12">
                      <div className="Content">
                        <h4>
                          NU Borders has the capabilities to manage the entire
                          IT service delivery process and setup for our clients
                          — from soup to nuts.
                        </h4>
                        <p className="pt-3">
                          Leveraging two distinct but interrelated methodologies
                          — Development and Operations Maintenance (DevOps) and
                          Agile Software Development — we can help automate the
                          processes between development and IT teams, increasing
                          collaboration and helping expedite the software
                          development process.
                        </p>
                        <p>
                          Ultimately, this enables NU Borders to develop and
                          deliver reliable data and IT solutions for our
                          clients.
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBTabPane>
                <MDBTabPane tabId="3" role="tabpanel">
                  <MDBRow>
                    <MDBCol size="12" md="4" sm="12">
                      <div className="Bussiness_Banner">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/toggle-4.png`}
                          alt=""
                        />
                      </div>
                    </MDBCol>
                    <MDBCol size="12" md="8" sm="12">
                      <div className="Content">
                        <h4>
                          Nearly every client NU Borders serves benefits from
                          custom software development to help meet their data
                          and IT needs.
                        </h4>
                        <p className="pt-3">
                          Specifically, we focus on developing
                          microservices-based software that provide our clients
                          a more modular approach to completing the data
                          services, capabilities, and tasks they require. This
                          helps to create a more resilient system overall while
                          enhancing and improving the user experience.
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBTabPane>
              </MDBTabContent>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <section
        className="bussiness_ands py-md-5 py-4 position-relative"
        id="deepLearning"
      >
        <MDBContainer>
          <MDBRow className="align-items-center">
            <MDBCol size="12" md="6" sm="12">
              <div className="Bussiness_Banner">
                <img
                  src={`${process.env.PUBLIC_URL}/images/anlatitic.png`}
                  alt=""
                />
              </div>
            </MDBCol>

            <MDBCol size="12" md="6" sm="12">
              <div className="Analytics pl-md-3 pl-0">
                <h1>
                  Analytics and Deep <br></br> Learning
                </h1>

                <div className="AnalyticsPera mt-3">
                  <p>
                    Data analytics has become an integral part of any
                    organization or government agency, especially with the rise
                    of Big Data tools and technology. Understanding, analyzing,
                    and managing large sets of data is vitally important for the
                    public and private sectors alike.
                  </p>
                </div>
              </div>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol size="12">
              <div className="anlyticsData mt-md-5 mt-3">
                <h3>
                  NU Borders’ data analytics services and solutions include:
                </h3>
                <p>
                  <span className="Bullet"></span> Designing, developing, and
                  maintaining an IT system to incorporate and leverage advanced
                  analytical tools that help clients collect, manage, and store
                  large amounts of data.
                </p>

                <p>
                  <span className="Bullet"></span> Using Deep Learning tools to
                  search, analyze, and visualize data based on our clients’
                  specific needs—helping clients leverage our solutions to
                  support their mission.
                </p>

                <p>
                  <span className="Bullet"></span> Leveraging advanced
                  analytics, data science, and artificial intelligence as well
                  as machine learning to advance, automate, and augment client
                  objectives.
                </p>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <section className="pt-5 pb-2 bg-F5F5F5">
        <MDBContainer>
          <MDBRow>
            <MDBCol size="12" md="12" sm="12">
              <div className="BottomTextHead">
                <h3>NU Borders’ Core IT Competencies</h3>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12" md="3" sm="12">
              <div className="SoftInners">
                <div className="SoftSoftInners">
                  <SoftwareDevelopmentICon />
                  <p>Software Development</p>
                  {/* <div className="ArrowIcon">
                                            <RightArrowIcon/>
                                        </div> */}
                </div>
              </div>
            </MDBCol>

            <MDBCol size="12" md="3" sm="12">
              <div className="SoftInners">
                <div className="SoftSoftInners">
                  <Datascience />
                  <p>Data science</p>
                  {/* <div className="ArrowIcon">
                                            <RightArrowIcon/>
                                        </div> */}
                </div>
              </div>
            </MDBCol>

            <MDBCol size="12" md="3" sm="12">
              <div className="SoftInners">
                <div className="SoftSoftInners">
                  <DeepLearning />
                  <p>Deep Learning</p>
                  {/* <div className="ArrowIcon">
                                            <RightArrowIcon/>
                                        </div> */}
                </div>
              </div>
            </MDBCol>

            <MDBCol size="12" md="3" sm="12">
              <div className="SoftInners">
                <div className="SoftSoftInners">
                  <DevOps />
                  <p>DevOps</p>
                  {/* <div className="ArrowIcon">
                                            <RightArrowIcon/>
                                        </div> */}
                </div>
              </div>
            </MDBCol>

            <MDBCol size="12" md="3" sm="12">
              <div className="SoftInners">
                <div className="SoftSoftInners">
                  <Architecture />
                  <p>Microservice Architecture</p>
                  {/* <div className="ArrowIcon">
                                            <RightArrowIcon/>
                                        </div> */}
                </div>
              </div>
            </MDBCol>

            <MDBCol size="12" md="3" sm="12">
              <div className="SoftInners">
                <div className="SoftSoftInners">
                  <ApplicationDevelopment />
                  <p>Application Development</p>
                  {/* <div className="ArrowIcon">
                                            <RightArrowIcon/>
                                        </div> */}
                </div>
              </div>
            </MDBCol>

            <MDBCol size="12" md="3" sm="12">
              <div className="SoftInners">
                <div className="SoftSoftInners">
                  <DataManagement />
                  <p>Data Management</p>
                  {/* <div className="ArrowIcon">
                                            <RightArrowIcon/>
                                        </div> */}
                </div>
              </div>
            </MDBCol>

            <MDBCol size="12" md="3" sm="12">
              <div className="SoftInners">
                <div className="SoftSoftInners">
                  <Cloudservices />
                  <p>Cloud Services</p>
                  {/* <div className="ArrowIcon">
                                            <RightArrowIcon/>
                                        </div> */}
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </React.Fragment>
  );
};

export default Service;
