import React from "react";

export const DropdownToggle = (props) => {
  return (
    <div {...props} className="dropdown-toggle">
      {props.children}
    </div>
  );
};
export const DropdownMenu = (props) => (
  <div className="dropdown-menu">
    <div
      {...props}
      data-test="dropdown-menu-component"
      tabIndex="-1"
      role="menu"
      aria-hidden="true"
    >
      {props.children}
    </div>
  </div>
);
