import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MDBRow, MDBCol, MDBBtn, MDBContainer } from "mdbreact";

class ZambesiaPartnershipBlog extends Component {
  state = {
    modal: false,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    return (
      <React.Fragment>
        <section className="Blog-Banner BlogDetails-Banner">
          <img src="" alt="" />
          <MDBContainer className="Blog-Title">
            <MDBRow>
              <MDBCol size="12">
                <div className="BannerTitle">
                  <h1>
                    NU Borders Partners with Zambesia Conservation Alliance to
                    use Advanced Analytics Solutions in the Fight Against
                    Illegal Wildlife Trade in Southern Africa
                  </h1>
                </div>

                <div className="Statusbar">
                  <div className="Timing">
                    <img src="" alt="" />
                    <h6>12/02/2021</h6>
                  </div>

                  <div className="Timing">
                    <img src="" alt="" />
                    <h6>Press Release</h6>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>

          <div className="Banner">
            <img
              src={`${process.env.PUBLIC_URL}/images/pexels-marc-mueller-380769.png`}
              className="banner-bg"
              alt=""
            />
          </div>
        </section>

        <section className="Blogs-Description">
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12">
                <div className="Description">
                  <p>
                    NU Borders LLC, a Boston, MA and Washington, DC-based data
                    analytics company, continues to grow its presence in the
                    conservation and anti-poaching domain. In September 2021, NU
                    Borders partnered with the{" "}
                    <a href="https://www.zambesia.com/">
                      Zambesia Conservation Alliance (ZCA)
                    </a>
                    , a non-profit organization focused on increasing awareness
                    of wildlife conservation efforts through meaningful and
                    impactful initiatives within the Zambesia region of Africa,
                    comprising the countries of Angola, Botswana, Malawi,
                    Mozambique, Namibia, Zambia, and Zimbabwe. ZCA and its
                    affiliates on the ground leverage advanced technology
                    including geo-location devices for anti-poaching units
                    (APUs) and GPS tagging for endangered species, to track and
                    protect animals targeted for illegal wildlife trade (IWT).
                  </p>
                  <p>
                    NU Borders will work closely with ZCA and its partners to
                    collect, ingest and analyze data collected in-the-field,
                    using the Business Intelligence for Trade Enforcement (BITE)
                    Big Data analytics platform. The platform includes
                    cutting-edge technology to identify poaching and illegal
                    wildlife trade activity across a spectrum of trade and
                    enforcement data. The goal is not only to identify
                    individual actors involved in poaching activities, but to
                    target transnational criminal organizations (TCOs) driving
                    the global IWT supply chain by joining international
                    manifest data to geo-tagged data. The BITE platform has
                    already been deployed in Southern Africa to support
                    endangered species protection with a focus on African
                    painted dogs.
                  </p>
                  <div>
                    <p>
                      “BITE quickly identifies trends and suspicious patterns
                      within the data we ingest from the field and allows
                      operational groups to make intelligence-driven decisions
                      for their day-to-day operations,” said NU Borders Chief
                      Operating Officer and retired Homeland Security
                      Investigations Special Agent, Dr. Tim Gildea.
                    </p>
                    <p>
                      The company’s core business function is focused on helping
                      enforcement, regulatory and compliance operators identify
                      threats and illicit activity in their supply chains for
                      several law enforcement domains including strategic trade
                      controls, intellectual property theft, wildlife
                      trafficking, human-wildlife conflict, drug smuggling and
                      money laundering. In 2018, NU Borders created the BITE
                      platform, a Software as a Service (SaaS) technology, to
                      help clients ingest and analyze voluminous, complex
                      operational datasets. BITE contains large, open-source
                      commercial trade datasets and screening lists, made
                      available to users across all domains. NU Borders' BITE
                      platform includes visualization tools and access to
                      hands-on training with our Data Scientists and Customs/
                      Border Enforcement Subject Matter Experts.{" "}
                    </p>
                  </div>
                  <div>
                    <p>
                      The ZCA was founded with the vision to become an effective
                      and credible collaborator for their conservation outreach
                      and strategic partners in the Zambesia region. ZCA’s
                      mission is to drive global awareness for wildlife
                      conservation through its engaging initiatives including
                      the Ranger Alert Campaign, #WeShareAfrica and
                      #SportForConservation, and the Alliance's Conservation
                      Games. After the Conservation Games were held, ZCA was
                      able to support the Victoria Falls Anti-Poaching Unit
                      (APU), Bumi Hills APU, Save Valley Conservancy and the
                      Victoria Falls Wildlife Trust. ZCA works with wildlife
                      trusts and IWT initiatives on the ground in the Zambesia
                      region to increase public awareness and communications
                      across relevant industries.{" "}
                    </p>
                  </div>
                  <p>
                    <a href="https://www.linkedin.com/in/lukebrown/">
                      Luke Brown
                    </a>{" "}
                    and{" "}
                    <a href="https://www.linkedin.com/in/robin-brown-7353a629/">
                      Robin Brown
                    </a>
                    , Co-Founders of ZCA, recently said, “Conservation practices
                    in the Zambesia region have been stalled by an often
                    inefficient collection, interpretation and communication of
                    expository information. Our partnership with NU Borders will
                    help to solve this critical process, preventing IWT by
                    bringing factual data to the forefront and allowing more
                    effective and positive action to take place. If we are able
                    to gather broad area data across multiple areas and patch it
                    together with BITE, we stand a chance in reducing the impact
                    of commercial poaching.”
                  </p>
                  <div>
                    <p>
                      Big Data analytics and real-time analyses are critical to
                      APUs, frontline workers and conservation groups who are
                      focused on protection of species and their habitats.
                      Through analyses and visualization of large datasets in
                      rapid time, BITE end-users can search on millions of
                      records to identify potential transshipment points of
                      highly trafficked species at risk. Advanced analytics help
                      law enforcement groups quickly analyze large, varied
                      datasets including legal court case information, and can
                      be further enhanced by overlaying manifest data to provide
                      insight on the global IWT supply chain. NU Borders’ BITE
                      tool suite includes workflow management and search
                      functions which can be used on the ground by APUs and law
                      enforcement to quickly create cases, search on other
                      datasets and share/export case information. NU Borders is
                      proud to partner with the ZCA to bring big data analytics
                      solutions to the Zambesia region and help frontline
                      conservation groups combat IWT and protect the global
                      supply chain.
                    </p>
                    <p>
                      For more information on the NU Borders and Zambesia
                      Conservation Alliance partnership, contact{" "}
                      <a href={"mailto:info@nuborders.com"}>
                        info@nuborders.com
                      </a>{" "}
                      or{" "}
                      <a href={"mailto:info@zambesia.com"}>info@zambesia.com</a>{" "}
                      today!
                    </p>
                  </div>
                  <div>
                    <img src="" alt="" />

                    <p></p>
                  </div>
                </div>
                <div>
                  <Link to="/blog">
                    <MDBBtn className="ThemeBtn">Back to blogs</MDBBtn>
                  </Link>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </React.Fragment>
    );
  }
}

export default ZambesiaPartnershipBlog;
